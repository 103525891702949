html {
  --font-base: 16px;
  font-size: var(--font-base);
}

.container {
  max-width: calc(1206px + 3rem);
  width: 100%;
  margin: 0 auto;
  padding: 0 1.5rem;
}

@media (max-width: 1446px) {
  html {
    font-size: calc(var(--font-base)  - 10.25%);
  }
}

@media (max-width: 980px) {
  html {
    font-size: calc(var(--font-base)  - 12.5%);
  }
}

@media (max-width: 340px) {
  html {
    font-size: calc(var(--font-base)  - 30%);
  }
}

body, p, input, h1, h2, ul, li {
  margin: 0;
  padding: 0;
  font-family: vinila;
}

li {
  list-style: none;
}

ol {
  padding: 0;
}

button {
  cursor: pointer;
  font-family: vinila;
}

:after, :before {
  box-sizing: border-box;
}

* {
  box-sizing: border-box;
  text-rendering: optimizelegibility;
  -webkit-font-smoothing: antialiased;
}

button {
  background: initial;
  border: initial;
  -webkit-appearance: none;
}

button:active {
  border-style: initial;
}

button:focus {
  outline: initial;
}

img {
  max-width: 100%;
  display: block;
}

fieldset {
  -webkit-margin-start: 0px;
  -moz-margin-start: 0px;
  -webkit-margin-end: 0px;
  -moz-margin-end: 0px;
  min-inline-size: -webkit-min-content;
  min-inline-size: -moz-min-content;
  min-inline-size: min-content;
  border: 2px #0000;
  border-image: initial;
  margin-inline: 0;
}

figure {
  margin-block: 0;
  margin-inline: 0;
  display: block;
}

input[type="submit"] {
  appearance: none;
}

:focus {
  outline: 0 #0000 !important;
}

input[type="submit"] {
  appearance: none;
}

.menu-container {
  z-index: 999;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
}

header.faq-menu {
  background: #5037ff;
  border-bottom: 1px solid #0000001a;
  padding: 16px 0;
}

header.faq-menu .container {
  justify-content: space-between;
  gap: 2.8rem;
  display: flex;
}

header.faq-menu .logo svg {
  width: 97px;
  height: 32px;
}

header.faq-menu nav {
  flex: 1;
  justify-content: flex-end;
  align-items: center;
  display: flex;
}

header.faq-menu ul {
  justify-content: space-between;
  align-items: center;
  display: flex;
}

header.faq-menu ul a {
  color: #fff;
  padding: 0 1rem;
  text-decoration: none;
  transition: all .2s;
}

header.faq-menu ul a:hover {
  color: #00ff8c;
}

header.faq-menu .social-media {
  max-width: 200px;
  width: 100%;
  justify-content: space-between;
  margin-left: auto;
  display: flex;
}

header.faq-menu .social-media.social-mobile {
  display: none;
}

@media screen and (max-width: 1024px) {
  header.faq-menu .social-media {
    display: none;
  }

  header.faq-menu .social-media.social-mobile {
    margin-left: unset;
    display: flex;
  }
}

header.faq-menu .social-media svg {
  width: 1.5rem;
  height: 1.5rem;
}

#btn-mobile {
  display: none;
}

@media screen and (max-width: 1024px) {
  #btn-mobile {
    display: block;
  }
}

#btn-mobile #hamburger {
  width: 20px;
  border-top: 2px solid #fff;
  display: block;
}

@media screen and (max-width: 1024px) {
  #btn-mobile #hamburger {
    display: block;
  }
}

#btn-mobile #hamburger:after, #btn-mobile #hamburger:before {
  content: "";
  width: 20px;
  height: 2px;
  background-color: #fff;
  margin-top: 5px;
  transition: all .3s;
  display: block;
  position: relative;
}

.faq-menu.active #btn-mobile #hamburger {
  color: #fff;
  border-top-color: #0000;
}

.faq-menu.active #btn-mobile #hamburger:before {
  transform: rotate(135deg);
}

.faq-menu.active #btn-mobile #hamburger:after {
  top: -7px;
  transform: rotate(-135deg);
}

@media screen and (max-width: 1024px) {
  header.faq-menu .menu-links {
    z-index: 1000;
    background: #5037ff;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding-top: 2rem;
    display: flex;
    position: fixed;
    inset: 60px 0 0;
    transform: translateX(-100%);
  }

  header.faq-menu .menu-links a {
    padding: 16px;
    display: block;
  }

  .social-mobile {
    margin-top: auto;
    margin-bottom: 3rem;
  }
}

footer.faq-footer {
  color: #fff;
  background: #1202a4;
}

footer.faq-footer .footer-wrapper {
  text-align: center;
  justify-content: space-between;
  align-items: center;
  padding: 2rem 0;
  display: flex;
}

@media screen and (max-width: 600px) {
  footer.faq-footer .footer-wrapper {
    text-align: left;
    flex-direction: column;
    align-items: flex-start;
    gap: 1.5rem;
    padding: 1.5rem 1rem;
  }
}

.about-menta {
  max-width: 25rem;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  display: flex;
}

@media screen and (max-width: 800px) {
  .about-menta {
    max-width: 18.75rem;
  }
}

@media screen and (max-width: 600px) {
  .about-menta {
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 1.5rem;
    margin-top: .5rem;
  }
}

.about-menta a {
  color: #fff;
  font-weight: 300;
  line-height: 1.25rem;
  text-decoration: underline;
  transition: all .2s;
  display: block;
}

.about-menta a:hover {
  color: #00ff8c;
}

.about-menta .about-col {
  text-align: left;
  flex-direction: column;
  gap: .5rem;
  display: flex;
}

.menta-online {
  flex-direction: column;
  align-items: flex-start;
  gap: .5rem;
  display: flex;
}

.menta-online span {
  margin-bottom: .625rem;
  font-size: 16px;
  font-weight: 700;
}

.menta-online .social-media {
  align-items: center;
  gap: 2rem;
  display: flex;
}

.menta-online .social-media a {
  height: 24px;
  transition: all .2s;
  display: block;
}

.menta-online .social-media a:hover {
  transform: scale(1.1);
}

.menta-online .social-media svg {
  color: #fff;
  width: 24px;
  height: 24px;
}

footer.faq-footer .all-rights-reserved {
  width: 100%;
  text-align: center;
  border-top: 1px solid #fff;
  justify-content: center;
  align-items: center;
  padding-block: 1.6rem;
  display: flex;
}

@media screen and (max-width: 600px) {
  footer.faq-footer .all-rights-reserved {
    flex-direction: column;
  }

  footer.faq-footer .all-rights-reserved span, footer.faq-footer .all-rights-reserved p {
    font-size: .875rem;
    line-height: 1.25rem;
  }
}

footer.faq-footer .all-rights-reserved p:before {
  content: "•";
  content: "";
  padding-inline: 8px;
}

.cta-banner-wrapper {
  max-width: 992px;
  width: 100%;
  background: #fff;
  border: 1px solid #a3a3a3;
  border-radius: 32px;
  justify-content: space-between;
  align-items: center;
  gap: 1rem;
  margin: 0 auto;
  padding: 1.5rem 2rem;
  display: flex;
  box-shadow: 0 1px 4px #100b3733, 0 3px 16px #100b371a;
}

@media screen and (max-width: 1024px) {
  .cta-banner-wrapper {
    flex-direction: column;
    padding: 1.5rem 1rem;
  }
}

.cta-banner-wrapper p {
  color: #0f0f0f;
  text-align: center;
  font-size: 1.3rem;
  font-weight: 700;
  line-height: 1.3;
}

.cta-banner-wrapper .btn-primary {
  color: #fff;
  cursor: pointer;
  color: #5037ff;
  background: #fff;
  border: 1px solid #5037ff;
  border-radius: 12px;
  flex-shrink: 0;
  justify-content: center;
  align-items: center;
  padding: 12px 2rem;
  font-size: 16px;
  font-weight: 700;
  text-decoration: none;
  transition: all .2s;
  display: flex;
  box-shadow: 0 1px 4px #100b371a, 0 1px 2px #100b374d;
}

.cta-banner-wrapper .btn-primary svg {
  width: 24px;
  height: 24px;
  margin-left: 8px;
}

.cta-banner-wrapper .btn-primary:hover {
  background: #1d00f5;
}

.cta-banner-wrapper .btn-primary svg {
  width: 24px;
  height: 24px;
}

.cta-banner-wrapper .btn-primary:hover {
  color: #fff;
}

@media screen and (max-width: 600px) {
  .cta-banner-wrapper .btn-primary {
    width: 100%;
  }
}

.modal {
  opacity: 0;
  pointer-events: none;
  z-index: 100;
  width: 100vw;
  height: 100vh;
  background: #00000059;
  transition: all .2s;
  position: fixed;
  top: 0;
  left: 0;
}

.modal.modal-active {
  opacity: 1;
}

.modal.modal-active .card-form {
  pointer-events: all;
  transform: translate(-50%, -50%)scale(1);
}

.modal .card-form svg.close-modal {
  color: #0f0f0f;
  cursor: pointer;
  width: 16px;
  height: 16px;
  position: absolute;
  top: 1.9rem;
  right: 2rem;
}

.modal .card-form svg.close-modal:hover {
  color: #c82400;
}

.modal header {
  flex-direction: column;
  gap: 1rem;
  display: flex;
}

.modal h4 {
  color: #5037ff;
  margin-block: 0;
  font-size: 2.25rem;
  line-height: 2.75rem;
}

.modal span {
  line-height: 1.5rem;
}

.modal .card-form {
  max-width: 587px;
  width: 96%;
  background: #fff;
  border-radius: 1rem;
  flex-direction: column;
  gap: 2.5rem;
  padding: 2.5rem 2rem;
  transition: all .2s;
  display: flex;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%)scale(0);
  box-shadow: 0 1px 4px #100b3733, 0 3px 16px #100b371a;
}

@media screen and (max-width: 600px) {
  .modal .card-form {
    width: 90%;
    padding-inline: 1rem;
  }
}

.modal .card-form .input-validation-field {
  flex-direction: column;
  gap: .25rem;
  display: flex;
  position: relative;
}

.modal .card-form .input-validation-field p {
  color: #c82400;
  font-size: .75rem;
  position: absolute;
  top: 4.25rem;
}

.modal .card-form .form-columns {
  grid-template-columns: 1fr 1fr;
  gap: 1.8vw;
  display: grid;
}

@media screen and (max-width: 600px) {
  .modal .card-form .form-columns {
    grid-template-columns: 1fr;
    gap: 2rem;
  }
}

.modal .card-form form {
  flex-direction: column;
  gap: 2.5rem;
  display: flex;
}

@media screen and (max-width: 600px) {
  .modal .card-form form {
    gap: 2rem;
  }
}

.modal .card-form input {
  width: 100%;
  color: #0f0f0f;
  color: #212529;
  appearance: none;
  background-clip: padding-box;
  border: 0;
  outline: 0;
  padding-block: .375rem;
  padding-inline: .625rem 0;
  font-size: 1rem;
  display: block;
}

.modal .card-form footer {
  align-items: center;
  gap: .5rem;
  display: flex;
}

.modal .card-form footer button {
  color: #fff;
  background: #857aea;
  border-radius: .75rem;
  flex: 1;
  justify-content: center;
  align-items: center;
  gap: 10px;
  padding: .75rem;
  font-size: 1rem;
  font-weight: 700;
  line-height: 1.5rem;
  transition: all .2s;
  display: flex;
}

.modal .card-form footer button svg {
  color: #fff;
}

.modal .card-form footer button:hover {
  background: #6f5cff;
}

.modal .card-form a.icon-question {
  width: -moz-fit-content;
  width: fit-content;
  border: 1px solid #5037ff;
  border-radius: .75rem;
  align-items: center;
  padding: .75rem;
  transition: all .2s;
  display: flex;
}

.modal .card-form a.icon-question:hover {
  background: #edebff;
}

.modal .card-form svg {
  width: 1.5rem;
  height: 1.5rem;
  color: #666;
}

.modal .input-field {
  width: 100%;
  border: 1px solid #c8c2ff;
  border-radius: .75rem;
  align-items: center;
  padding: 1rem 0 1rem 1rem;
  display: flex;
}

.modal .input-field.valido {
  background-image: url("valid.f237cf0f.svg");
  background-position: right 1.125rem center;
  background-repeat: no-repeat;
  background-size: 1.5rem;
  padding-right: 1.125rem;
  border: 1px solid #1cc800 !important;
}

.modal .input-field.valido input {
  width: calc(100% - 48px);
}

.modal .input-field.invalido {
  background-image: url("invalid.9471f642.svg");
  background-position: right 1.125rem center;
  background-repeat: no-repeat;
  background-size: 1.5rem;
  padding-right: 1rem;
  border: 2px solid #c82400 !important;
}

.modal .input-field.invalido input {
  width: calc(100% - 48px);
}

.modal .input-field:focus-within {
  border: 2px solid #2648be;
}

.modal .input-field:focus-within svg {
  color: #0f0f0f;
}

body {
  opacity: 0;
}

main {
  overflow: hidden;
}

.PageFaq {
  background: #ebeaf4;
  font-family: vinila;
  overflow-x: hidden;
}

.PageFaq .section-header {
  min-height: 164px;
  background: #00ff8c;
  justify-content: space-between;
  gap: 4rem;
  margin-top: 69px;
  padding-top: 2.5rem;
  display: flex;
  position: relative;
}

@media screen and (max-width: 800px) {
  .PageFaq .section-header {
    flex-direction: column;
    gap: .5rem;
    padding: 3rem 0;
  }
}

.PageFaq .section-header .section-content {
  width: max-content;
  position: relative;
  top: 28px;
}

@media screen and (max-width: 600px) {
  .PageFaq .section-header .section-content {
    top: unset;
  }
}

.PageFaq .section-header h2 {
  color: #5037ff;
  text-transform: uppercase;
  font-size: 3.375rem;
  font-weight: 900;
  line-height: 1;
}

@media screen and (max-width: 600px) {
  .PageFaq .section-header h2 {
    text-align: center;
  }
}

.PageFaq .section-header .title-bg {
  width: max-content;
  text-align: center;
  background: #5037ff;
  margin-bottom: 16px;
  padding: .5rem 2.8rem .1rem;
  transform: rotate(-4deg);
}

.PageFaq .section-header .title-bg h2 {
  color: #fff;
  line-height: 1;
}

.PageFaq .section-header h2.relative {
  width: max-content;
  margin-left: auto;
  position: relative;
  left: 3rem;
}

@media screen and (max-width: 600px) {
  .PageFaq .section-header h2.relative {
    left: unset;
    text-align: center;
    margin-right: auto;
  }
}

.PageFaq .section-header .faq-img {
  max-width: 48vw;
  position: absolute;
  bottom: -20px;
  right: 0;
}

@media screen and (max-width: 1024px) {
  .PageFaq .section-header .faq-img {
    max-width: 42vw;
  }
}

@media screen and (max-width: 800px) {
  .PageFaq .section-header .faq-img {
    max-width: 100vw;
    margin-left: auto;
    position: static;
  }
}

@media screen and (max-width: 600px) {
  .PageFaq .section-header .title-bg {
    padding-inline: .45rem;
  }
}

@media screen and (max-width: 800px) {
  .PageFaq .section-header .faq-img {
    position: relative;
    left: 1.5rem;
  }

  .PageFaq .section-header .faq-img img {
    margin-left: auto;
  }
}

.PageFaq .faq-cards {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1.5rem;
  padding-block: 6.25rem;
  padding-inline: 1.5rem;
  display: flex;
}

@media screen and (max-width: 600px) {
  .PageFaq .faq-cards {
    padding-block: 3.5rem 6.25rem;
    padding-inline: .5rem;
  }
}

.PageFaq .faq-card {
  max-width: 1000px;
  width: 100%;
  background: #fff;
  border-radius: 8px;
  flex-direction: column;
  gap: 1rem;
  padding: 1.5rem;
  display: flex;
  box-shadow: 0 1px 4px #100b3733, 0 3px 16px #100b371a;
}

.PageFaq .faq-card .faq-card-title {
  height: -moz-fit-content;
  height: fit-content;
  gap: 1rem;
  display: flex;
}

.PageFaq .faq-card .faq-card-title svg {
  width: 2rem;
  height: 2rem;
}

.PageFaq .faq-card .faq-card-title h5 {
  color: #1202a4;
  margin-block: 0;
  padding-block: 0;
  font-size: 1.5rem;
  font-weight: bold;
  line-height: 2rem;
}

.PageFaq .faq-card span {
  color: #0f0f0f;
  font-size: 1rem;
  line-height: 1.5rem;
}

.PageEmBreve .conteudo-intermediario {
  justify-content: center;
  display: flex;
}

.PageEmBreve .conteudo-intermediario .card-form {
  width: 30.5rem;
  background: #fff;
  border-radius: 1rem;
  padding: 2rem 1.5rem;
  box-shadow: 0 .0625rem .25rem #100b3733, 0 .1875rem 1rem #100b371a;
}

.PageEmBreve .conteudo-intermediario .card-form h2 {
  color: #4f37ff;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 2rem;
}

.PageEmBreve .conteudo-intermediario .card-form p {
  width: 22rem;
  color: #0f0f0f;
  margin-top: 1rem;
  font-size: .875rem;
  font-weight: 400;
  line-height: 1.375rem;
}

.PageEmBreve .conteudo-intermediario .card-form .formulario .input-field {
  margin-top: 2rem;
  position: relative;
}

.PageEmBreve .conteudo-intermediario .card-form .formulario .input-field img {
  left: .75rem;
  right: initial;
  width: 1.5rem;
  height: 1.5rem;
  margin: 0 auto;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.PageEmBreve .conteudo-intermediario .card-form .formulario .input-field input {
  width: 100%;
  padding: .75rem 1rem;
  color: #666;
  background: #fff;
  border: .0625rem solid #c8c2ff;
  border-radius: .75rem;
  padding-left: 2.5rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5rem;
}

.PageEmBreve .conteudo-intermediario .card-form .formulario .input-icon-right {
  width: 100%;
  position: relative;
}

.PageEmBreve .conteudo-intermediario .card-form .formulario .input-icon-right img {
  right: 30%;
  left: initial;
  width: 1.5rem;
  height: 1.5rem;
  margin: 0 auto;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.PageEmBreve .conteudo-intermediario .card-form .formulario .conteudo-final {
  align-items: center;
  gap: .5rem;
  margin-top: 1.5rem;
  display: flex;
}

.PageEmBreve .conteudo-intermediario .card-form .formulario .conteudo-final .icon-question {
  min-width: 3rem;
  height: 3rem;
  width: 3rem;
  cursor: pointer;
  border: .0625rem solid #4f37ff;
  border-radius: .75rem;
  place-items: center;
  padding: 0;
  transition: all .3s;
  display: grid;
  position: relative;
}

.PageEmBreve .conteudo-intermediario .card-form .formulario .conteudo-final .icon-question .tooltip {
  width: 25rem;
  height: 8.125rem;
  color: #6f5cff;
  z-index: 100;
  opacity: 0;
  pointer-events: none;
  background: #fff;
  border: .0625rem solid #5037ff;
  border-radius: .375rem;
  place-items: center;
  margin: 0 auto;
  padding: .5rem;
  font-size: 1rem;
  line-height: 150%;
  transition: all .3s;
  display: grid;
  position: absolute;
  bottom: calc(100% + .3125rem);
  left: 0;
  right: 0;
  box-shadow: 0 .125rem .5rem 0 #63636333;
}

.PageEmBreve .conteudo-intermediario .card-form .formulario .conteudo-final .icon-question:hover {
  background-color: #edebff;
}

.PageEmBreve .conteudo-intermediario .card-form .formulario .conteudo-final .icon-question:hover .tooltip {
  opacity: 1;
}

.PageEmBreve .conteudo-intermediario .card-form .formulario .conteudo-final .icon-question img {
  width: 1.5rem;
  height: 1.5rem;
  z-index: 2;
  position: relative;
}

.PageEmBreve .conteudo-intermediario .card-form .formulario .conteudo-final button {
  width: 100%;
  color: #fff;
  cursor: pointer;
  background: #857aea;
  border-radius: .75rem;
  padding: .75rem 1.5rem;
  font-size: 1rem;
  font-weight: 700;
  transition: background .3s;
}

.PageEmBreve .conteudo-intermediario .card-form .formulario .conteudo-final button svg {
  transition: transform .3s;
}

.PageEmBreve .conteudo-intermediario .card-form .formulario .conteudo-final button:hover {
  background: #6f5cff;
}

.PageEmBreve .conteudo-intermediario .card-form .formulario .conteudo-final button:hover svg {
  transform: translateX(6.875rem);
}

@media screen and (max-width: 1024px) {
  .PageEmBreve .conteudo-intermediario .card-form .formulario .conteudo-final button:hover svg {
    transform: translateX(50px);
  }
}

.PageEmBreve .conteudo .img-clouds img {
  width: 100%;
  height: 100%;
  z-index: -1;
  position: absolute;
  top: 7.8125rem;
  bottom: 0;
  left: 0;
}

.PageEmBreve .conteudo .img-circle img {
  width: 100%;
  height: 100%;
  z-index: -1;
  position: absolute;
  top: 16.6875rem;
  bottom: 0;
  left: 0;
}

.PageEmBreve .conteudo .conteudo-intermediario .img-airplane img {
  max-width: 40.4375rem;
  width: 100%;
  object-fit: contain;
}

.PageEmBreve {
  overflow: hidden;
}

.PageEmBreve .intro {
  max-width: -moz-fit-content;
  max-width: fit-content;
  background: #00ff8c;
  margin-inline: auto;
  padding-inline: 1.5rem;
  transform: rotate(-10deg);
}

.PageEmBreve h1 {
  color: #5037ff;
  margin-block: 6rem;
  font-size: 5.5rem;
  font-weight: 900;
  line-height: 7rem;
}

.PageEmBreve h1 .conteudo-intermediario {
  gap: 4rem;
  display: flex;
}

.PageEmBreve svg {
  width: 1.5rem;
  height: 1.5rem;
}

.PageEmBreve .quebra {
  display: none;
}

.PageEmBreve .cloud-1 {
  width: calc(100vw + 400px);
  height: auto;
  z-index: -2;
  margin: 0 auto;
  position: fixed;
  top: 200px;
  left: -200px;
  right: -200px;
}

.PageEmBreve .cloud-2 {
  width: calc(100vw + 400px);
  height: auto;
  z-index: -1;
  margin: 0 auto;
  position: fixed;
  top: 250px;
  left: -200px;
  right: -200px;
}

@media (min-width: 1111px) {
  .PageEmBreve.PageEmBreve .conteudo {
    height: calc(100vh - 5rem);
    margin-top: clamp(1.875rem, 21vh, 30rem) !important;
  }

  .PageEmBreve.PageEmBreve h1 {
    margin: 0;
    padding-top: 5px;
  }

  .PageEmBreve.PageEmBreve header.faq-menu {
    width: 100%;
    height: 5rem;
    position: fixed;
    top: 0;
    left: 0;
  }

  .PageEmBreve.PageEmBreve .intro {
    height: max-content;
    width: max-content;
    margin-bottom: 2.5rem;
    overflow: hidden;
    transform: rotate(-9deg) !important;
  }

  .PageEmBreve.PageEmBreve h1 {
    line-height: 150%;
    font-size: clamp(1.875rem, 3.8vw, 5.5rem) !important;
  }

  .PageEmBreve.PageEmBreve .formulario .input-field {
    position: relative;
  }

  .PageEmBreve.PageEmBreve .formulario .input-field input {
    padding-left: 3rem !important;
  }

  .PageEmBreve.PageEmBreve .formulario .input-field svg {
    color: #666;
    position: absolute;
    top: .75rem;
    left: 1rem;
  }

  .PageEmBreve.PageEmBreve .conteudo-intermediario {
    margin-top: 11vh;
  }

  .PageEmBreve.PageEmBreve .conteudo-intermediario .card-form .formulario .conteudo-final button {
    height: 3rem;
    justify-content: center;
    align-items: center;
    gap: .5rem;
    display: flex;
    padding: 0 1.5rem !important;
  }

  .PageEmBreve.PageEmBreve header.faq-menu .logo svg {
    width: 7.25rem;
    height: 2.5rem;
  }

  .PageEmBreve.PageEmBreve .conteudo .conteudo-intermediario .img-airplane img {
    margin-left: 2rem;
  }
}

@media (max-width: 1100px) {
  .PageEmBreve.PageEmBreve {
    overflow-y: auto;
  }

  .PageEmBreve.PageEmBreve .img-circle {
    display: none !important;
  }

  .PageEmBreve.PageEmBreve .intro {
    width: max-content;
    max-width: auto;
  }

  .PageEmBreve.PageEmBreve h1 {
    max-width: auto;
    text-align: center;
    line-height: 117%;
    margin-bottom: 1rem !important;
    font-size: 48px !important;
  }

  .PageEmBreve.PageEmBreve .conteudo-intermediario .card-form .formulario .conteudo-final button {
    height: 3rem;
    justify-content: center;
    align-items: center;
    gap: .5rem;
    display: flex;
    padding: 0 1.5rem !important;
  }

  .PageEmBreve.PageEmBreve .quebra {
    display: block;
  }

  .PageEmBreve.PageEmBreve .conteudo-intermediario {
    align-items: center;
    flex-direction: column-reverse !important;
  }

  .PageEmBreve.PageEmBreve .conteudo-intermediario .card-form {
    width: 100%;
    max-width: clamp(300px, 100vw - 2rem, 400px);
    margin-bottom: 42px;
  }

  .PageEmBreve.PageEmBreve .img-airplane {
    max-width: 90vw;
  }

  .PageEmBreve.PageEmBreve .formulario .input-field {
    position: relative;
  }

  .PageEmBreve.PageEmBreve .formulario .input-field input {
    padding-left: 3rem !important;
  }

  .PageEmBreve.PageEmBreve .formulario .input-field svg {
    color: #666;
    position: absolute;
    top: .75rem;
    left: 1rem;
  }
}

.PagePost h3 {
  color: #5037ff;
  margin-block: 0;
  font-size: 3rem;
  font-weight: 900;
  line-height: 3.75rem;
}

.PagePost h6 {
  color: #0f0f0f;
  margin-block: 0;
  font-size: 1.25rem;
  font-weight: 700;
  line-height: 1.75rem;
}

.PagePost p {
  line-height: 1.5rem;
}

.PagePost .post-container {
  max-width: 85.375rem;
  width: 100%;
  margin-inline: auto;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 100px;
  padding-inline: 1.5rem;
  display: flex;
}

.PagePost .post-container a.back-button {
  width: 100%;
  color: #5037ff;
  align-items: center;
  gap: .75rem;
  margin-block: 2.5rem;
  padding-inline: 5vw;
  font-weight: 700;
  text-decoration: none;
  display: flex;
}

.PagePost .post-container svg {
  width: .625rem;
  height: 1.0625rem;
}

.PagePost .post-container .post-wrapper {
  max-width: 49.625rem;
  width: 100%;
  flex-direction: column;
  gap: 2.5rem;
  display: flex;
}

@media screen and (max-width: 800px) {
  .PagePost .post-container .post-wrapper {
    gap: 1.5rem;
  }
}

.PagePost .post-container .post-wrapper .main-img {
  max-width: 56.0625rem;
}

.PagePost .post-container .post-wrapper img {
  border-radius: 16px 16px 0 0;
}

.PagePost .post-container .post-wrapper .post-header {
  flex-direction: column;
  gap: 1rem;
  display: flex;
}

.PagePost .post-container .post-wrapper .post-header .post-infos {
  color: #474747;
  align-items: center;
  gap: 1.25rem;
  display: flex;
}

@media screen and (max-width: 800px) {
  .PagePost .post-container .post-wrapper .post-header .post-infos {
    flex-direction: column;
    align-items: flex-start;
    gap: .5rem;
  }
}

.PagePost .post-container .post-wrapper .post-header .post-infos .post-info {
  align-items: center;
  gap: .625rem;
  display: flex;
}

.PagePost .post-container .post-wrapper .post-header svg {
  color: #474747;
  width: 1.5rem;
  height: 1.5rem;
}

.PagePost .post-container .post-wrapper .post-content {
  flex-direction: column;
  gap: 1rem;
  display: flex;
}

.PagePost .post-container .post-wrapper .post-columns {
  justify-content: center;
  align-items: center;
  gap: 2rem;
  display: flex;
}

@media screen and (max-width: 800px) {
  .PagePost .post-container .post-wrapper .post-columns {
    flex-direction: column;
  }
}

.PagePost .post-container .author-container {
  border-block: 1px solid #a3a3a3;
  grid-template-columns: 56px 1fr;
  gap: 1rem;
  padding-block: 3.4375rem;
  display: grid;
}

.PagePost .post-container .author-container .author-info {
  flex-direction: column;
  gap: .5rem;
  display: flex;
}

.PagePost .post-container .author-container .author-info strong {
  font-size: 1.125rem;
}

.PagePost .post-container .author-container .author-info span {
  font-weight: 300;
  line-height: 1.5rem;
}

.PagePost .post-container .next-reading {
  padding-bottom: 5.25rem;
}

.PagePost .post-container .next-reading h5 {
  color: #1d00f5;
  margin-block: 1rem 2rem;
  font-size: 1.5rem;
}

.PagePost .post-container .next-reading h6 {
  color: #5037ff;
  text-align: center;
  line-height: 1.75rem;
}

.PagePost .post-container .next-reading img {
  width: 100%;
  border-radius: 8px;
}

.PagePost .post-container .next-reading .next-post-card {
  min-height: 16rem;
  flex-direction: column;
  gap: 1rem;
  display: flex;
}

.PagePost .post-container .next-reading .next-post-card a {
  color: #5037ff;
  align-items: center;
  gap: .5938rem;
  margin-top: auto;
  margin-left: auto;
  text-decoration: none;
  display: flex;
}

.PagePost .post-container .next-reading .next-post-card a svg {
  width: .9375rem;
  height: .7813rem;
}

.PageBlog {
  background: #ebeaf4;
}

.PageBlog .section-header {
  min-height: 164px;
  min-height: 190px;
  background: #00ff8c;
  justify-content: space-between;
  gap: 4rem;
  margin-top: 69px;
  padding-top: 2.5rem;
  display: flex;
  position: relative;
}

@media screen and (max-width: 800px) {
  .PageBlog .section-header {
    flex-direction: column;
    gap: .5rem;
    padding: 3rem 0;
  }
}

.PageBlog .section-header .section-content {
  width: max-content;
  position: relative;
  top: 28px;
}

@media screen and (max-width: 600px) {
  .PageBlog .section-header .section-content {
    top: unset;
  }
}

.PageBlog .section-header h2 {
  color: #5037ff;
  text-transform: uppercase;
  font-size: 3.375rem;
  font-weight: 900;
  line-height: 1;
}

@media screen and (max-width: 600px) {
  .PageBlog .section-header h2 {
    text-align: center;
  }
}

.PageBlog .section-header .title-bg {
  width: max-content;
  text-align: center;
  background: #5037ff;
  margin-bottom: 16px;
  padding: .5rem 2.8rem .1rem;
  transform: rotate(-4deg);
}

.PageBlog .section-header .title-bg h2 {
  color: #fff;
  line-height: 1;
}

.PageBlog .section-header h2.relative {
  width: max-content;
  margin-left: auto;
  position: relative;
  left: 3rem;
}

@media screen and (max-width: 600px) {
  .PageBlog .section-header h2.relative {
    left: unset;
    text-align: center;
    margin-right: auto;
  }
}

.PageBlog .section-header .faq-img {
  max-width: 48vw;
  position: absolute;
  bottom: -20px;
  right: 0;
}

@media screen and (max-width: 1024px) {
  .PageBlog .section-header .faq-img {
    max-width: 42vw;
  }
}

@media screen and (max-width: 800px) {
  .PageBlog .section-header .faq-img {
    max-width: 100vw;
    margin-left: auto;
    position: relative;
    left: 1.5rem;
  }
}

.PageBlog .section-header .faq-img img {
  margin-left: auto;
}

.PageBlog .blog-wrapper {
  max-width: 1206px;
  flex-direction: column;
  gap: 2rem;
  margin-block: 6.25rem;
  margin-inline: auto;
  padding-inline: 1.5rem;
  display: flex;
}

.PageBlog .blog-wrapper .main-card {
  width: 100%;
  background: #fff;
  border-radius: 16px;
  grid-template-columns: 1fr 1fr;
  align-items: center;
  display: grid;
  box-shadow: 0 1px 4px #100b3733, 0 3px 16px #100b371a;
}

@media screen and (max-width: 800px) {
  .PageBlog .blog-wrapper .main-card {
    grid-template-columns: 1fr;
  }
}

.PageBlog .blog-wrapper .main-card .main-card-img {
  height: 100%;
}

.PageBlog .blog-wrapper .main-card img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: bottom;
  border-radius: 16px 0 0 16px;
}

@media screen and (max-width: 800px) {
  .PageBlog .blog-wrapper .main-card img {
    border-radius: 16px 16px 0 0;
  }
}

.PageBlog .blog-wrapper .main-card .main-card-info {
  flex-direction: column;
  gap: 1.5rem;
  padding: 1.8rem;
  display: flex;
}

.PageBlog .blog-wrapper .main-card h5 {
  color: #5037ff;
  margin-block: 0;
  font-size: 1.5rem;
  line-height: 32px;
}

.PageBlog .blog-wrapper .main-card p {
  color: #0f0f0f;
  line-height: 1.5rem;
}

.PageBlog .blog-wrapper .main-card a {
  color: #5037ff;
  align-items: center;
  gap: .5938rem;
  margin-top: 2.5rem;
  margin-left: auto;
  text-decoration: none;
  display: flex;
}

.PageBlog .blog-wrapper .main-card a svg {
  width: .9375rem;
  height: .7813rem;
}

.PageBlog .blog-wrapper .swiper {
  margin-inline: 0;
}

.PageBlog .blog-wrapper .swiper .swiper-slide {
  border-radius: 8px;
  box-shadow: 0 1px 4px #100b3733, 0 3px 16px #100b371a;
}

.PageBlog .blog-wrapper .blog-card {
  min-height: 23rem;
  background: #fff;
  border-radius: 8px;
  flex-direction: column;
  display: flex;
}

.PageBlog .blog-wrapper .blog-card img {
  width: 100%;
  height: 100%;
  border-radius: 8px 8px 0 0;
}

.PageBlog .blog-wrapper .blog-card h6 {
  color: #5037ff;
  margin-block: 0;
  padding: 1.5rem 1.5rem 0;
  font-size: 24px;
  line-height: 2rem;
}

.PageBlog .blog-wrapper .blog-card a {
  color: #5037ff;
  width: -moz-fit-content;
  width: fit-content;
  align-items: center;
  gap: .5938rem;
  margin: auto 1.5rem 1.5rem auto;
  font-weight: 700;
  text-decoration: none;
  display: flex;
}

.PageBlog .blog-wrapper .blog-card a svg {
  width: .9375rem;
  height: .7813rem;
}

.PageBlog .blog-wrapper .menta-way {
  width: 100%;
  color: #fff;
  text-align: center;
  background: url("blog_img2.5d65de47.png") 0 0 / cover no-repeat;
  border-radius: 8px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: .5rem;
  margin-block: 2.25rem;
  padding: 4.5rem 1.5rem;
  display: flex;
}

.PageBlog .blog-wrapper .menta-way button {
  color: #fff;
  cursor: pointer;
  max-width: 23.75rem;
  width: 100%;
  background: #5037ff;
  border-radius: 8px;
  justify-content: center;
  margin-top: 2rem;
  padding-block: .75rem;
  font-size: 1rem;
  font-weight: 700;
  text-decoration: none;
  transition: all .2s;
  display: flex;
}

.PageBlog .blog-wrapper .menta-way button:hover {
  background: #1d00f5;
}

.PageBlog .blog-wrapper .menta-way h3 {
  margin-block: 0;
  font-size: 3rem;
  font-weight: 900;
  line-height: 3.75rem;
}

.PageBlog .blog-wrapper .menta-way span {
  font-size: 18px;
  font-weight: 700;
}

.PageBlog .see-more {
  justify-content: center;
  display: flex;
}

.PageBlog .see-more a {
  color: #5037ff;
  max-width: 36.8125rem;
  width: 100%;
  text-align: center;
  border: 1px solid #5037ff;
  border-radius: 12px;
  margin-top: 32px;
  padding: 1.125rem;
  font-weight: 700;
  text-decoration: none;
  transition: all .2s;
}

.PageBlog .see-more a:hover {
  color: #fff;
  background: #5037ff;
}

.PageHome {
  background: #ebeaf4;
  overflow-x: hidden;
}

.PageHome #banner-hero-2 {
  height: 100vh;
  visibility: hidden;
  max-width: 2560px;
  margin: 0 auto;
  position: relative;
  overflow: hidden;
}

.PageHome #banner-hero-2 .container:not(.container-2) {
  height: 100%;
  z-index: 15;
  flex-direction: column;
  justify-content: flex-end;
  display: flex;
  position: relative;
  height: calc(100vh - 100px) !important;
}

.PageHome #banner-hero-2 .container:not(.container-1) {
  z-index: 1;
  flex-direction: column;
  justify-content: flex-end;
  display: flex;
  position: absolute;
  inset: 0;
  height: calc(100vh - 70px) !important;
}

.PageHome #banner-hero-2 .container-2 .intro {
  margin-bottom: 6rem;
  margin-left: auto;
}

.PageHome #banner-hero-2 .container-2 .intro .title {
  justify-content: flex-end;
}

.PageHome #banner-hero-2 .content-wrapper {
  padding-bottom: 3rem;
  position: absolute;
  inset: 0;
}

.PageHome #banner-hero-2 .intro {
  z-index: 10;
  position: relative;
  transform: rotate(-10deg);
}

.PageHome #banner-hero-2 .intro .title {
  display: flex;
}

@media (max-width: 767px) {
  .PageHome #banner-hero-2 .intro .title {
    flex-direction: column;
    align-items: center;
  }
}

.PageHome #banner-hero-2 .intro .title span {
  color: #5037ff;
  max-width: max-content;
  background-color: #00ff8c;
  padding: 10px;
  font-size: clamp(3.5rem, 4.4vw, 5.5rem);
  font-weight: 900;
  display: block;
}

@media (max-width: 378px) {
  .PageHome #banner-hero-2 .intro .title span {
    font-size: 3rem;
  }
}

.PageHome #banner-hero-2 .intro .title .to-break {
  margin-left: -1px;
}

@media (max-width: 767px) {
  .PageHome #banner-hero-2 .intro .title .to-break {
    margin-top: -1px;
  }
}

.PageHome #banner-hero-2 .plane-wrapper {
  pointer-events: none;
  z-index: 16;
  position: relative;
}

@media (max-width: 1600px) {
  .PageHome #banner-hero-2 .plane-wrapper {
    max-height: 200px;
  }
}

.PageHome #banner-hero-2 .plane-wrapper img {
  height: 100%;
  margin: 0 auto;
}

.PageHome #banner-hero-2 .cta-banner-wrapper {
  z-index: 15;
  max-width: 992px;
  width: 100%;
  background: #fff;
  border-radius: 32px;
  justify-content: space-between;
  align-items: center;
  gap: 1rem;
  margin: 0 auto;
  padding: 1.5rem 2rem;
  display: flex;
  position: relative;
  box-shadow: 0 1px 4px #100b3733, 0 3px 16px #100b371a;
}

@media screen and (max-width: 1024px) {
  .PageHome #banner-hero-2 .cta-banner-wrapper {
    flex-direction: column;
    padding: 1.5rem 1rem;
  }
}

.PageHome #banner-hero-2 .cta-banner-wrapper p {
  color: #0f0f0f;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1.3;
}

@media screen and (max-width: 800px) {
  .PageHome #banner-hero-2 .cta-banner-wrapper p {
    text-align: center;
    font-size: 1.3rem;
  }
}

.PageHome #banner-hero-2 .cta-banner-wrapper .btn-primary {
  color: #fff;
  cursor: pointer;
  background: #5037ff;
  border-radius: 12px;
  flex-shrink: 0;
  justify-content: center;
  align-items: center;
  padding: 12px 2rem;
  font-size: 16px;
  font-weight: 700;
  text-decoration: none;
  transition: all .2s;
  display: flex;
  box-shadow: 0 1px 4px #100b371a, 0 1px 2px #100b374d;
}

.PageHome #banner-hero-2 .cta-banner-wrapper .btn-primary svg {
  width: 24px;
  height: 24px;
  margin-left: 8px;
}

.PageHome #banner-hero-2 .cta-banner-wrapper .btn-primary:hover {
  background: #1d00f5;
}

@media screen and (max-width: 600px) {
  .PageHome #banner-hero-2 .cta-banner-wrapper .btn-primary {
    width: 100%;
  }
}

.PageHome #banner-hero-2 .ceu-banner-2 {
  height: 100%;
  pointer-events: none;
}

.PageHome #banner-hero-2 .ceu-banner-2 img {
  height: 100%;
  object-fit: cover;
  width: 100%;
}

.PageHome #banner-hero-2 .to-stay {
  height: 100vh;
  z-index: 10;
  pointer-events: none;
  flex-direction: column;
  justify-content: flex-end;
  display: flex;
  position: absolute;
  top: 0;
  overflow: hidden;
}

.PageHome #banner-hero-2 .segundo-plano img {
  height: 400px;
  object-fit: cover;
  object-position: left;
  margin-bottom: -100px;
}

.PageHome #banner-hero-2 .pista img {
  height: 236px;
  object-fit: cover;
  object-position: left;
}

.PageHome #sobre {
  margin-top: 7.5rem;
  margin-bottom: 7.5rem;
  scroll-margin-top: 7.5rem;
}

.PageHome #sobre .intro {
  max-width: max-content;
  margin-bottom: 3rem;
  transform: rotate(-10deg);
}

.PageHome #sobre .intro .title {
  color: #5037ff;
  font-size: clamp(3rem, 4.4vw, 3rem);
  font-weight: 900;
}

.PageHome #sobre .intro .title span {
  background-color: #00ff8c;
  padding: 10px;
}

.PageHome #sobre .intro .title .to-break {
  margin-left: -16px;
}

@media screen and (max-width: 800px) {
  .PageHome #sobre .intro .title .to-break {
    margin-left: 0;
    display: block;
    position: relative;
    left: 50%;
  }
}

.PageHome #sobre .sobre-grid {
  grid-template-columns: 1fr 1fr;
  gap: 6.25rem;
  margin-bottom: 5rem;
  display: grid;
}

@media screen and (max-width: 800px) {
  .PageHome #sobre .sobre-grid {
    grid-template-columns: 1fr;
    gap: 3rem;
  }
}

.PageHome #sobre .sobre-grid .col-2 .title {
  color: #5037ff;
  margin-bottom: 8px;
  font-size: 2.25rem;
  font-weight: 700;
}

.PageHome #sobre .sobre-grid .col-2 .sobre-wrapper .sub-title {
  color: #0f0f0f;
  margin-bottom: 1.5rem;
  font-size: 1.125rem;
  font-weight: 700;
}

.PageHome #sobre .sobre-grid .col-2 .sobre-wrapper p {
  color: #0f0f0f;
  margin-bottom: 1rem;
  font-size: 14px;
  line-height: 1.4;
}

.PageHome #sobre .sobre-grid .col-2 .sobre-cards {
  margin-top: 1.5rem;
}

.PageHome #sobre .sobre-grid .col-2 .sobre-cards .card-item {
  max-width: 250px;
  width: 100%;
  background-color: #fff;
  border: 1px solid #00ff8c;
  border-radius: 16px;
  margin-bottom: 1rem;
  padding: 8px 24px 8px 40px;
  position: relative;
  box-shadow: 0 1px 4px #100b3733, 0 3px 8px #100b371a;
}

@media screen and (max-width: 800px) {
  .PageHome #sobre .sobre-grid .col-2 .sobre-cards .card-item {
    margin-left: auto;
    margin-right: auto;
  }
}

.PageHome #sobre .sobre-grid .col-2 .sobre-cards .card-item svg {
  width: 3.5rem;
  height: 3.5rem;
  position: absolute;
  top: 50%;
  left: -1.75rem;
  transform: translateY(-50%);
}

.PageHome #sobre .sobre-grid .col-2 .sobre-cards .card-item p {
  font-size: 1rem;
  line-height: 1.4;
}

.PageHome #sobre .sobre-grid .col-2 .sobre-cards .card-item span {
  font-weight: 700;
}

.PageHome #sobre .sobre-grid .col-2 .sobre-cards .card-item:nth-child(2) {
  margin-left: auto;
  margin-right: auto;
}

.PageHome #sobre .sobre-grid .col-2 .sobre-cards .card-item:nth-child(3) {
  margin-left: auto;
}

.PageHome #sobre .sobre-grid .col-2 .text-experimenta {
  color: #5037ff;
  margin-top: 1.5rem;
  font-size: 1.125rem;
  font-weight: 700;
  line-height: 1.3;
}

.PageHome #faq {
  background-color: #5037ff;
  padding-top: 10rem;
  padding-bottom: 10rem;
}

.PageHome #faq .intro {
  max-width: max-content;
  margin-bottom: 3rem;
  margin-left: auto;
  margin-right: auto;
  position: relative;
  right: 106px;
  transform: rotate(-10deg);
}

@media screen and (max-width: 800px) {
  .PageHome #faq .intro {
    right: 0;
  }
}

.PageHome #faq .intro .title {
  color: #5037ff;
  font-size: clamp(3rem, 4.4vw, 3rem);
  font-weight: 900;
}

.PageHome #faq .intro .title span {
  background-color: #00ff8c;
  padding: 10px;
  display: block;
}

.PageHome #faq .intro .title .to-break {
  width: max-content;
  margin-top: -1px;
  margin-left: 0;
  display: block;
  position: relative;
  left: 70%;
}

@media screen and (max-width: 800px) {
  .PageHome #faq .intro .title .to-break {
    left: 1rem;
  }
}

.PageHome #faq .swiper-faq {
  margin-bottom: 6rem;
}

@media screen and (max-width: 1024px) {
  .PageHome #faq .swiper-faq {
    overflow: visible;
  }
}

.PageHome #faq .swiper-faq .swiper-slide {
  height: auto;
}

.PageHome #faq .faq-cards .faq-card {
  height: 100%;
  background-color: #fff;
  border-bottom: 5px solid #00ff8c;
  border-radius: 8px;
  flex-direction: column;
  padding: 24px;
  display: flex;
  box-shadow: 0 1px 4px #100b3733, 0 3px 16px #100b371a;
}

.PageHome #faq .faq-cards .faq-card svg {
  width: 3.5rem;
  height: 3.5rem;
  margin-bottom: 1rem;
}

.PageHome #faq .faq-cards .faq-card .pergunta-title {
  color: #0f0f0f;
  margin-bottom: 1rem;
  font-size: 16px;
  font-weight: 700;
  line-height: 1.3;
}

.PageHome #faq .faq-cards .faq-card .resposta {
  color: #0f0f0f;
  margin-bottom: 1.4rem;
  font-size: 14px;
  line-height: 1.4;
}

.PageHome #faq .faq-cards .faq-card .saber-mais {
  margin-top: auto;
}

.PageHome #faq .cta-banner-wrapper {
  max-width: 910px;
}

.PageHome #contato {
  padding: 8rem 0;
}

.PageHome #contato .intro {
  max-width: max-content;
  margin-bottom: 3rem;
  transform: rotate(-10deg);
}

@media screen and (max-width: 600px) {
  .PageHome #contato .intro {
    margin-left: auto;
    margin-right: auto;
  }
}

.PageHome #contato .intro .title {
  color: #5037ff;
  font-size: clamp(3rem, 4.4vw, 3rem);
  font-weight: 900;
}

.PageHome #contato .intro .title .hide-mobile {
  display: none;
}

@media (min-width: 768px) {
  .PageHome #contato .intro .title .hide-mobile {
    padding: 0;
    display: inline-block;
  }
}

.PageHome #contato .intro .title .hide-desktop {
  display: none;
}

@media (max-width: 768px) {
  .PageHome #contato .intro .title .hide-desktop {
    padding: 0;
    display: inline-block;
  }
}

.PageHome #contato .intro .title span {
  width: max-content;
  background-color: #00ff8c;
  padding: 10px;
  display: block;
}

@media (max-width: 768px) {
  .PageHome #contato .intro .title span {
    margin: 0 auto;
  }
}

.PageHome #contato .intro .title .to-break {
  margin-left: auto;
  display: block;
  position: relative;
  left: 5rem;
}

@media (max-width: 768px) {
  .PageHome #contato .intro .title .to-break {
    left: unset;
  }
}

.PageHome #contato .grid-wrapper {
  grid-template-columns: 1fr 1fr;
  align-items: flex-end;
  gap: 1rem;
  display: grid;
}

@media screen and (max-width: 1024px) {
  .PageHome #contato .grid-wrapper {
    grid-template-columns: 1fr;
  }
}

.PageHome #contato .grid-wrapper .images-group {
  transform: translateY(120px);
}

@media (max-width: 1670px) {
  .PageHome #contato .grid-wrapper .images-group {
    transform: translateY(120px)scale(.8);
  }
}

@media (max-width: 1024px) {
  .PageHome #contato .grid-wrapper .images-group {
    transform: translateY(0);
  }
}

@media screen and (max-width: 1024px) {
  .PageHome #contato .grid-wrapper .images-group {
    grid-row: 1;
  }
}

.PageHome #contato .grid-wrapper .images-group .step {
  position: relative;
}

.PageHome #contato .grid-wrapper .images-group .step:nth-child(2) {
  transform: translateY(-44px);
}

@media screen and (max-width: 800px) {
  .PageHome #contato .grid-wrapper .images-group .step:nth-child(2) {
    transform: translateY(-30px);
  }
}

.PageHome #contato .grid-wrapper .images-group .step:nth-child(2) img {
  filter: grayscale();
  margin: 0 auto;
  right: 10px;
}

@media (max-width: 1024px) {
  .PageHome #contato .grid-wrapper .images-group .step:nth-child(2) img {
    filter: none;
  }
}

.PageHome #contato .grid-wrapper .images-group .step:nth-child(3) {
  transform: translateY(-120px);
}

.PageHome #contato .grid-wrapper .images-group .step:nth-child(3) img {
  filter: grayscale();
  margin-left: auto;
  margin-right: 10px;
}

@media (max-width: 1024px) {
  .PageHome #contato .grid-wrapper .images-group .step:nth-child(3) img {
    filter: none;
  }
}

.PageHome #contato .card-form {
  max-width: 587px;
  background: #fff;
  border-radius: 1rem;
  flex-direction: column;
  gap: 2.5rem;
  padding: 2.5rem 2rem;
  transition: all .2s;
  display: flex;
  box-shadow: 0 1px 4px #100b3733, 0 3px 16px #100b371a;
}

@media screen and (max-width: 600px) {
  .PageHome #contato .card-form {
    padding-inline: 1rem;
  }
}

.PageHome #contato .card-form header {
  flex-direction: column;
  gap: 1rem;
  display: flex;
}

.PageHome #contato .card-form h4 {
  color: #5037ff;
  margin-block: 0;
  font-size: 2.25rem;
  line-height: 2.75rem;
}

.PageHome #contato .card-form span {
  line-height: 1.5rem;
}

.PageHome #contato .input-validation-field {
  flex-direction: column;
  gap: .25rem;
  display: flex;
  position: relative;
}

.PageHome #contato .input-validation-field p {
  color: #c82400;
  font-size: .75rem;
  position: absolute;
  top: 4.25rem;
}

.PageHome #contato .form-columns {
  grid-template-columns: 1fr 1fr;
  gap: 1.8vw;
  display: grid;
}

@media screen and (max-width: 600px) {
  .PageHome #contato .form-columns {
    grid-template-columns: 1fr;
    gap: 2rem;
  }
}

.PageHome #contato form {
  flex-direction: column;
  gap: 2.5rem;
  display: flex;
}

@media screen and (max-width: 600px) {
  .PageHome #contato form {
    gap: 2rem;
  }
}

.PageHome #contato input {
  width: 100%;
  color: #0f0f0f;
  color: #212529;
  appearance: none;
  background-clip: padding-box;
  border: 0;
  outline: 0;
  padding-block: .375rem;
  padding-inline: .625rem 0;
  font-size: 1rem;
  display: block;
}

.PageHome #contato footer {
  align-items: center;
  gap: .5rem;
  display: flex;
}

.PageHome #contato footer button {
  color: #fff;
  background: #857aea;
  border-radius: .75rem;
  flex: 1;
  justify-content: center;
  align-items: center;
  gap: 10px;
  padding: .75rem;
  font-size: 1rem;
  font-weight: 700;
  line-height: 1.5rem;
  transition: all .2s;
  display: flex;
}

.PageHome #contato footer button svg {
  color: #fff;
}

.PageHome #contato footer button:hover {
  background: #6f5cff;
}

.PageHome #contato a.icon-question {
  width: -moz-fit-content;
  width: fit-content;
  border: 1px solid #5037ff;
  border-radius: .75rem;
  align-items: center;
  padding: .75rem;
  transition: all .2s;
  display: flex;
}

.PageHome #contato a.icon-question:hover {
  background: #edebff;
}

.PageHome #contato svg {
  width: 1.5rem;
  height: 1.5rem;
  color: #666;
}

.PageHome #contato .input-field {
  width: 100%;
  border: 1px solid #c8c2ff;
  border-radius: .75rem;
  align-items: center;
  padding: 1rem 0 1rem 1rem;
  display: flex;
}

.PageHome #contato .input-field.valido {
  background-image: url("valid.f237cf0f.svg");
  background-position: right 1.125rem center;
  background-repeat: no-repeat;
  background-size: 1.5rem;
  padding-right: 1.125rem;
  border: 1px solid #1cc800 !important;
}

.PageHome #contato .input-field.valido input {
  width: calc(100% - 48px);
}

.PageHome #contato .input-field.invalido {
  background-image: url("invalid.9471f642.svg");
  background-position: right 1.125rem center;
  background-repeat: no-repeat;
  background-size: 1.5rem;
  padding-right: 1rem;
  border: 2px solid #c82400 !important;
}

.PageHome #contato .input-field.invalido input {
  width: calc(100% - 48px);
}

.PageHome #contato .input-field:focus-within {
  border: 2px solid #2648be;
}

.PageHome #contato .input-field:focus-within svg {
  color: #0f0f0f;
}

.PageHome #blog {
  background-color: #5037ff;
  padding: 10rem 0;
}

.PageHome #blog .intro {
  max-width: max-content;
  margin-bottom: 7rem;
  margin-left: auto;
  margin-right: auto;
  position: relative;
  right: 0;
  transform: rotate(-10deg);
}

.PageHome #blog .intro .title {
  color: #5037ff;
  font-size: clamp(3rem, 4.4vw, 3rem);
  font-weight: 900;
}

.PageHome #blog .intro .title span {
  background-color: #00ff8c;
  margin-left: -15px;
  padding: 10px;
}

@media screen and (max-width: 600px) {
  .PageHome #blog .intro .title span {
    width: max-content;
    margin: 0 auto;
    display: block;
  }

  .PageHome #blog .intro .title .to-break {
    margin-top: -2px;
    display: block;
  }
}

.PageHome #blog .blog-wrapper {
  flex-direction: column;
  gap: 2rem;
  display: flex;
}

.PageHome #blog .main-card {
  width: 100%;
  background: #fff;
  border-radius: 16px;
  grid-template-columns: 1fr 1fr;
  align-items: center;
  display: grid;
  box-shadow: 0 1px 4px #100b3733, 0 3px 16px #100b371a;
}

@media screen and (max-width: 800px) {
  .PageHome #blog .main-card {
    grid-template-columns: 1fr;
  }
}

.PageHome #blog .main-card .main-card-img {
  height: 100%;
}

.PageHome #blog .main-card img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: bottom;
  border-radius: 16px 0 0 16px;
}

@media screen and (max-width: 800px) {
  .PageHome #blog .main-card img {
    border-radius: 16px 16px 0 0;
  }
}

.PageHome #blog .main-card .main-card-info {
  flex-direction: column;
  gap: 1.5rem;
  padding: 2rem;
  display: flex;
}

@media screen and (max-width: 1024px) {
  .PageHome #blog .main-card .main-card-info {
    padding: 1.5rem 1rem;
  }
}

.PageHome #blog .main-card h5 {
  color: #5037ff;
  margin-block: 0;
  font-size: 1.5rem;
  line-height: 1.3;
}

.PageHome #blog .main-card p {
  color: #0f0f0f;
  line-height: 1.5rem;
}

.PageHome #blog .main-card a {
  color: #5037ff;
  align-items: center;
  gap: .5938rem;
  margin-top: 2.5rem;
  margin-left: auto;
  text-decoration: none;
  display: flex;
}

.PageHome #blog .main-card a svg {
  width: .9375rem;
  height: .7813rem;
}

.PageHome #blog .swiper {
  margin-inline: 0;
}

.PageHome #blog .swiper .swiper-slide {
  border-radius: 8px;
  box-shadow: 0 1px 4px #100b3733, 0 3px 16px #100b371a;
}

.PageHome #blog .blog-card {
  min-height: 23rem;
  background: #fff;
  border-radius: 8px;
  flex-direction: column;
  display: flex;
}

.PageHome #blog .blog-card img {
  width: 100%;
  height: 100%;
  border-radius: 8px 8px 0 0;
}

.PageHome #blog .blog-card h6 {
  color: #5037ff;
  margin-block: 0;
  padding: 1.5rem 1.5rem 0;
  font-size: 24px;
  line-height: 2rem;
}

.PageHome #blog .blog-card a {
  color: #5037ff;
  width: -moz-fit-content;
  width: fit-content;
  align-items: center;
  gap: .5938rem;
  margin: auto 1.5rem 1.5rem auto;
  font-weight: 700;
  text-decoration: none;
  display: flex;
}

.PageHome #blog .blog-card a svg {
  width: .9375rem;
  height: .7813rem;
}

.PageHome #blog .blog-card h6 {
  font-size: 1.5rem;
}

.PageHome #blog .menta-way {
  width: 100%;
  color: #fff;
  text-align: center;
  background: url("blog_img2.5d65de47.png") 0 0 / cover no-repeat;
  border-radius: 8px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: .5rem;
  margin-block: 2.25rem;
  padding: 4.5rem 1.5rem;
  display: flex;
}

.PageHome #blog .menta-way button {
  color: #fff;
  cursor: pointer;
  max-width: 23.75rem;
  width: 100%;
  background: #5037ff;
  border-radius: 8px;
  justify-content: center;
  margin-top: 2rem;
  padding-block: .75rem;
  font-size: 1rem;
  font-weight: 700;
  text-decoration: none;
  transition: all .2s;
  display: flex;
}

.PageHome #blog .menta-way button:hover {
  background: #1d00f5;
}

.PageHome #blog h3 {
  margin-block: 0;
  font-size: 3rem;
  font-weight: 900;
  line-height: 3.75rem;
}

.PageHome #blog .see-more {
  justify-content: center;
  display: flex;
}

.PageHome #blog .see-more a {
  color: #5037ff;
  max-width: 36.8125rem;
  width: 100%;
  text-align: center;
  border: 1px solid #5037ff;
  border-radius: 12px;
  margin-top: 32px;
  padding: 1.125rem;
  font-weight: 700;
  text-decoration: none;
  transition: all .2s;
}

.PageHome #blog .see-more a:hover {
  color: #fff;
  background: #5037ff;
}

.PageHome #trabalhe-conosco {
  padding: 10rem 0;
  padding-bottom: 5rem;
}

.PageHome #trabalhe-conosco .intro {
  max-width: max-content;
  margin-bottom: 7rem;
  margin-left: auto;
  margin-right: auto;
  position: relative;
  right: 0;
  transform: rotate(-10deg);
}

@media screen and (max-width: 1024px) {
  .PageHome #trabalhe-conosco .intro {
    max-width: 320px;
    margin-bottom: 3rem;
    padding: 0 1.5rem;
  }
}

.PageHome #trabalhe-conosco .intro .title {
  color: #5037ff;
  font-size: clamp(3rem, 4.4vw, 3rem);
  font-weight: 900;
  line-height: 1;
}

.PageHome #trabalhe-conosco .intro .title span {
  background-color: #00ff8c;
  margin-left: -15px;
  padding: 10px;
}

@media screen and (max-width: 1024px) {
  .PageHome #trabalhe-conosco .intro .title span {
    text-transform: capitalize;
  }

  .PageHome #trabalhe-conosco .intro .title .to-break {
    width: max-content;
    display: block;
  }
}

.PageHome #trabalhe-conosco .intro .title .to-break:nth-child(2) {
  margin: 0 auto;
}

.PageHome #trabalhe-conosco .intro .title .to-break:nth-child(3) {
  margin-left: auto;
}

@media (max-width: 1024px) {
  .PageHome #trabalhe-conosco .swiper-trabalhe {
    overflow: visible;
  }
}

.PageHome #trabalhe-conosco .card-trabalho {
  background-color: #fff;
  border: 1px solid #fff;
  border-radius: 16px;
  overflow: hidden;
}

.PageHome #trabalhe-conosco .card-trabalho .img-wrapper img {
  width: 100%;
}

.PageHome #trabalhe-conosco .card-trabalho svg {
  width: 18px;
  height: 18px;
}

.PageHome #trabalhe-conosco .card-trabalho .content {
  padding: 16px 16px 19px;
}

.PageHome #trabalhe-conosco .card-trabalho .content .title {
  color: #0f0f0f;
  margin-bottom: 8px;
  font-size: 14px;
  font-weight: 700;
  line-height: 1.3;
}

.PageHome #trabalhe-conosco .card-trabalho .content .info-vaga {
  color: #666;
  margin-bottom: 18px;
  font-size: 14px;
}

.PageHome #trabalhe-conosco .card-trabalho .ver-mais {
  color: #5037ff;
  letter-spacing: .0015em;
  justify-content: center;
  align-items: center;
  font-weight: 700;
  text-decoration: none;
  display: flex;
}

.PageHome #trabalhe-conosco .card-trabalho .ver-mais svg {
  margin-left: 8px;
}

.PageHome #trabalhe-conosco .wrapper-info {
  max-width: 586px;
  text-align: center;
  margin: 2rem auto 0;
}

.PageHome #trabalhe-conosco .wrapper-info .ver-mais {
  color: #5037ff;
  letter-spacing: .0015em;
  justify-content: center;
  align-items: center;
  margin-top: 1.5rem;
  font-weight: 700;
  text-decoration: none;
  display: flex;
}

.PageHome #trabalhe-conosco .wrapper-info .ver-mais svg {
  width: 18px;
  height: 18px;
  margin-left: 8px;
}

.PageTrabalhe {
  background: #ebeaf4;
}

.PageTrabalhe .section-header {
  min-height: 164px;
  background: #00ff8c;
  justify-content: space-between;
  gap: 4rem;
  margin-top: 69px;
  padding-top: 2.5rem;
  display: flex;
  position: relative;
}

@media screen and (max-width: 800px) {
  .PageTrabalhe .section-header {
    flex-direction: column;
    gap: .5rem;
    padding: 3rem 0;
  }
}

.PageTrabalhe .section-header .section-content {
  width: max-content;
  position: relative;
  top: 28px;
}

@media screen and (max-width: 600px) {
  .PageTrabalhe .section-header .section-content {
    top: unset;
  }
}

.PageTrabalhe .section-header h2 {
  color: #5037ff;
  text-transform: uppercase;
  font-size: 3.375rem;
  font-weight: 900;
  line-height: 1;
}

@media screen and (max-width: 600px) {
  .PageTrabalhe .section-header h2 {
    text-align: center;
  }
}

.PageTrabalhe .section-header .title-bg {
  width: max-content;
  text-align: center;
  background: #5037ff;
  margin-bottom: 16px;
  padding: .5rem 2.8rem .1rem;
  transform: rotate(-4deg);
}

.PageTrabalhe .section-header .title-bg h2 {
  color: #fff;
  line-height: 1;
}

.PageTrabalhe .section-header h2.relative {
  width: max-content;
  margin-left: auto;
  position: relative;
  left: 3rem;
}

@media screen and (max-width: 600px) {
  .PageTrabalhe .section-header h2.relative {
    left: unset;
    text-align: center;
    margin-right: auto;
  }
}

.PageTrabalhe .section-header .faq-img {
  max-width: 48vw;
  position: absolute;
  bottom: -20px;
  right: 0;
}

@media screen and (max-width: 1024px) {
  .PageTrabalhe .section-header .faq-img {
    max-width: 42vw;
  }
}

@media screen and (max-width: 800px) {
  .PageTrabalhe .section-header .faq-img {
    max-width: 100vw;
    margin-left: auto;
    position: static;
  }
}

.PageTrabalhe #vagas {
  z-index: 2;
  margin-bottom: 3.375rem;
  padding-top: 8rem;
  position: relative;
}

@media screen and (max-width: 800px) {
  .PageTrabalhe #vagas {
    padding-top: 5rem;
  }
}

.PageTrabalhe #vagas .grid-wrapper {
  grid-template-columns: repeat(3, 1fr);
  gap: 2rem;
  margin-bottom: 2rem;
  display: grid;
}

@media screen and (max-width: 800px) {
  .PageTrabalhe #vagas .grid-wrapper {
    grid-template-columns: repeat(1, 1fr);
    gap: 1rem;
  }
}

.PageTrabalhe #vagas .grid-wrapper .group select {
  width: 100%;
  color: #666;
  appearance: none;
  height: 3.5rem;
  cursor: pointer;
  background-color: #fff;
  background-image: url("chevron-down.6381c671.svg");
  background-position: 98%;
  background-repeat: no-repeat;
  background-size: 24px;
  border: 1px solid #c8c2ff;
  border-radius: 12px;
  padding: 1rem;
  font-family: Vinila;
}

.PageTrabalhe #vagas .vagas-wrapper .vaga {
  background-color: #fff;
  border-radius: 16px;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
  padding: 1.875rem 1.5rem;
  text-decoration: none;
  display: flex;
  box-shadow: 0 1px 4px #100b3733, 0 3px 16px #100b371a;
}

@media screen and (max-width: 800px) {
  .PageTrabalhe #vagas .vagas-wrapper .vaga {
    padding: 1rem;
  }
}

.PageTrabalhe #vagas .vagas-wrapper .vaga .infos {
  flex: 1;
  grid-template-columns: 1fr 1fr 1fr;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
  display: grid;
}

@media screen and (max-width: 800px) {
  .PageTrabalhe #vagas .vagas-wrapper .vaga .infos {
    display: block;
  }
}

.PageTrabalhe #vagas .vagas-wrapper .vaga .infos .title {
  color: #0f0f0f;
}

.PageTrabalhe #vagas .vagas-wrapper .vaga .infos p {
  color: #666;
  line-height: 1.3;
}

.PageTrabalhe #vagas .vagas-wrapper .vaga .ver-link .link {
  color: #5037ff;
  align-items: center;
  text-decoration: none;
  display: flex;
}

.PageTrabalhe #vagas .vagas-wrapper .vaga .ver-link svg {
  width: 18px;
  height: 18px;
  margin-left: 8px;
}

.SingleTrabalhe {
  background: #ebeaf4;
}

.SingleTrabalhe #content {
  margin-top: 69px;
  margin-bottom: 4rem;
  padding-top: 2rem;
  position: relative;
}

@media screen and (max-width: 800px) {
  .SingleTrabalhe #content {
    padding-top: 2rem;
  }
}

.SingleTrabalhe #content:after {
  content: "";
  width: 100%;
  height: 249px;
  z-index: -1;
  background-color: #00ff8c;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
}

.SingleTrabalhe #content .go-back {
  color: #5037ff;
  margin-bottom: 2rem;
  font-size: 14px;
  font-weight: 700;
  text-decoration: none;
  display: inline-block;
}

.SingleTrabalhe #content .container {
  max-width: calc(796px + 2rem);
}

.SingleTrabalhe #content .card-content {
  background: #fff;
  border-radius: 16px;
  box-shadow: 0 1px 4px #100b3733, 0 3px 16px #100b371a;
}

.SingleTrabalhe #content .header {
  border-bottom: 1px solid #e0e0e0;
  justify-content: space-between;
  align-items: flex-end;
  padding: 4rem 3rem 2rem;
  display: flex;
}

@media screen and (max-width: 800px) {
  .SingleTrabalhe #content .header {
    justify-content: unset;
    gap: 1.5rem;
    padding: 16px;
    display: grid;
  }
}

.SingleTrabalhe #content .infos .title {
  color: #0f0f0f;
  margin-bottom: 1rem;
  font-size: 2.25rem;
  font-weight: 700;
  line-height: 1.4;
}

@media screen and (max-width: 800px) {
  .SingleTrabalhe #content .infos .title {
    margin-bottom: 8px;
    font-size: 1.5rem;
  }
}

.SingleTrabalhe #content .infos .info-flex {
  color: #666;
  align-items: center;
  gap: 5px;
  display: flex;
}

.SingleTrabalhe #content .btn-primary {
  color: #fff;
  cursor: pointer;
  background: #5037ff;
  border-radius: 12px;
  justify-content: center;
  align-items: center;
  padding: 8px 1.5rem;
  font-size: 16px;
  font-weight: 700;
  text-decoration: none;
  transition: all .2s;
  display: flex;
}

.SingleTrabalhe #content .btn-primary svg {
  width: 24px;
  height: 24px;
  margin-left: 8px;
}

.SingleTrabalhe #content .btn-primary:hover {
  background: #1d00f5;
}

.SingleTrabalhe #content .btn-primary svg {
  color: #fff;
}

.SingleTrabalhe #content .content {
  color: #666;
  padding: 2rem 3rem 4rem;
  line-height: 1.4;
}

@media screen and (max-width: 800px) {
  .SingleTrabalhe #content .content {
    padding: 16px;
  }
}

.SingleTrabalhe #content .content li {
  margin-bottom: 8px;
  margin-left: 16px;
  list-style: disc;
}

.SingleTrabalhe #content .content .responsabilidades {
  margin-bottom: 2rem;
}

.SingleTrabalhe #content .content .title-content {
  color: #0f0f0f;
  margin-bottom: 1rem;
  font-size: 1.125rem;
  font-weight: 700;
}

.PageContato {
  background: #ebeaf4;
}

.PageContato .section-header {
  min-height: 164px;
  background: #00ff8c;
  justify-content: space-between;
  gap: 4rem;
  margin-top: 69px;
  padding-top: 2.5rem;
  display: flex;
  position: relative;
}

@media screen and (max-width: 800px) {
  .PageContato .section-header {
    flex-direction: column;
    gap: .5rem;
    padding: 3rem 0;
  }
}

.PageContato .section-header .section-content {
  width: max-content;
  position: relative;
  top: 28px;
}

@media screen and (max-width: 600px) {
  .PageContato .section-header .section-content {
    top: unset;
  }
}

.PageContato .section-header h2 {
  color: #5037ff;
  text-transform: uppercase;
  font-size: 3.375rem;
  font-weight: 900;
  line-height: 1;
}

@media screen and (max-width: 600px) {
  .PageContato .section-header h2 {
    text-align: center;
  }
}

.PageContato .section-header .title-bg {
  width: max-content;
  text-align: center;
  background: #5037ff;
  margin-bottom: 16px;
  padding: .5rem 2.8rem .1rem;
  transform: rotate(-4deg);
}

.PageContato .section-header .title-bg h2 {
  color: #fff;
  line-height: 1;
}

.PageContato .section-header h2.relative {
  width: max-content;
  margin-left: auto;
  position: relative;
  left: 3rem;
}

@media screen and (max-width: 600px) {
  .PageContato .section-header h2.relative {
    left: unset;
    text-align: center;
    margin-right: auto;
  }
}

.PageContato .section-header .faq-img {
  max-width: 48vw;
  position: absolute;
  bottom: -20px;
  right: 0;
}

@media screen and (max-width: 1024px) {
  .PageContato .section-header .faq-img {
    max-width: 42vw;
  }
}

@media screen and (max-width: 800px) {
  .PageContato .section-header .faq-img {
    max-width: 100vw;
    margin-left: auto;
    position: static;
  }
}

.PageContato .section-header .section-content {
  padding-top: 2.5rem;
  padding-bottom: 2.5rem;
  top: 0;
}

@media screen and (max-width: 800px) {
  .PageContato .section-header .faq-img {
    position: relative;
    left: 1.5rem;
  }
}

.PageContato .section-header .faq-img img {
  margin-left: auto;
}

.PageContato #contato {
  margin-bottom: 4.5rem;
  padding-top: 6.25rem;
}

.PageContato #contato .grid-wrapper {
  grid-template-columns: .7fr .3fr;
  align-items: flex-start;
  gap: 2rem;
  display: grid;
}

@media screen and (max-width: 1024px) {
  .PageContato #contato .grid-wrapper {
    grid-template-columns: 1fr;
  }
}

.PageContato #contato .grid-wrapper .col-1, .PageContato #contato .grid-wrapper .side-bar {
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 1px 4px #100b3733, 0 3px 16px #100b371a;
}

.PageContato #contato .grid-wrapper .col-1 {
  padding-bottom: 1.5rem;
}

.PageContato #contato .grid-wrapper .col-1 h2 {
  border-bottom: 1px solid #a3a3a3;
  padding: 1.5rem;
}

.PageContato #contato .grid-wrapper .col-1 p {
  color: #0f0f0f;
  font-weight: 400;
  line-height: 1.3;
}

.PageContato #contato .grid-wrapper .col-1 .descricao {
  margin-bottom: 1.5rem;
}

.PageContato #contato .grid-wrapper .col-1 .bloco-wrapper {
  padding: 1.5rem 1.5rem 0;
}

.PageContato #contato .grid-wrapper .col-1 .bloco-wrapper .blog-group {
  border-bottom: 1px solid #a3a3a3;
}

.PageContato #contato .grid-wrapper .col-1 .bloco-wrapper .bloco {
  margin-bottom: 1.5rem;
}

.PageContato #contato .grid-wrapper .col-1 .bloco-wrapper .bloco ul:not(.no-disc) {
  list-style: disc;
}

.PageContato #contato .grid-wrapper .col-1 .bloco-wrapper .bloco ul:not(.no-disc) li {
  margin-left: 1.5rem;
  list-style: disc;
}

.PageContato #contato .grid-wrapper .col-1 .bloco-wrapper .bloco p {
  margin-bottom: 8px;
}

.PageContato #contato .grid-wrapper .col-1 .bloco-wrapper .bloco a {
  color: #0f0f0f;
  margin-bottom: 8px;
  font-weight: 700;
  text-decoration: none;
  display: block;
}

.PageContato #contato .grid-wrapper .col-1 .bloco-wrapper .bloco .chat-link {
  color: #5037ff;
  text-decoration: underline;
}

@media screen and (max-width: 1024px) {
  .PageContato #contato .grid-wrapper .col-2 {
    grid-row: 1;
  }
}

.PageContato #contato .grid-wrapper .col-2 .side-bar {
  margin-bottom: 1.5rem;
  padding: 1.5rem;
}

.PageContato #contato .grid-wrapper .col-2 .side-bar .icon-wrapper {
  align-items: center;
  margin-bottom: 1rem;
  display: flex;
}

.PageContato #contato .grid-wrapper .col-2 .side-bar .icon-wrapper svg {
  width: 2.5rem;
  height: 2.5rem;
  color: #1202a4;
  margin-right: 1rem;
}

.PageContato #contato .grid-wrapper .col-2 .side-bar .icon-wrapper p {
  color: #1202a4;
  font-size: 1.25rem;
  font-weight: 700;
}

.PageContato #contato .grid-wrapper .col-2 .side-bar .side-info p {
  color: #1202a4;
  margin-bottom: 5px;
}

.PageContato #contato .grid-wrapper .col-2 .side-bar .side-info a {
  color: #1202a4;
  font-weight: 700;
}

/*# sourceMappingURL=index.css.map */
