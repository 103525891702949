&
  background: $background
  overflow-x: hidden

#banner-hero-2
  // overflow-x: hidden
  overflow: hidden
  height: 100vh
 
  position: relative
 
  visibility: hidden
  margin-top: 69px
  // margin-bottom: calc(2800px - 100vh)

  max-width: 2560px
  margin: 0 auto

  @media (min-height: 1900px)
    // height: 50vh
  // max-height: 1000px

  .to-stay
    // height: 100% !important
  .container:not(.container-2)
    height: calc(100vh - 100px) !important

  .container:not(.container-2)
    position: relative
    height: 100%
    display: flex
    flex-direction: column
    justify-content: flex-end
    z-index: 15
   
  .container:not(.container-1)
    height: calc(100vh - 70px) !important
    display: flex
    flex-direction: column
    justify-content: flex-end
    
    position: absolute
    inset: 0
    z-index: 1
  .container-2 .intro
    margin-left: auto 
    margin-bottom: 6rem
    .title
      justify-content: flex-end
    // margin-top: -100px
      
  .content-wrapper
    position: absolute
    inset: 0
    padding-bottom: 3rem
    // z-index: 8
    // isolation: isolate
    

  .intro
   
    transform: rotate(-10deg)
    position: relative
    z-index: 10
   

    .title
     
      display: flex
      @media (max-width: 767px)
        // display: block
        flex-direction: column
        align-items: center
      span
        color: #5037FF
        padding: 10px
        font-weight: 900
        display: block
        font-size: clamp(3.5rem, 4.4vw, 5.5rem)
        background-color: $green-500 
        max-width: max-content
        @media (max-width: 378px)
          font-size: 3rem
      .to-break
        margin-left: -1px
        @media (max-width: 767px)
          margin-top: -1px
          

  .plane-wrapper
    pointer-events: none
    position: relative
    z-index: 16
    // margin-bottom: 48px
    @media (max-width: 1600px)
      max-height: 200px
    img
      margin: 0 auto
      height: 100%

  .cta-banner-wrapper
    // stack 
    position: relative
    z-index: 15

    background: #fff
    padding: 1.5rem 2rem
    box-shadow: 0px 1px 4px rgba(16, 11, 55, 0.2), 0px 3px 16px rgba(16, 11, 55, 0.1)
    border-radius: 32px

    display: flex
    justify-content: space-between
    align-items: center
    gap: 1rem
   
    max-width: 992px
    width: 100%
    margin: 0 auto
    
    
    
    @include d(md2)
     flex-direction: column
    @include d(md2)
     padding: 1.5rem 1rem
    p
     color: #0F0F0F
     font-size: 1.5rem
     font-weight: 700
     line-height: 1.3

     @include d(md)
      font-size: 1.3rem
      text-align: center
      

    .btn-primary
      @include Botao()
      box-shadow: 0px 1px 4px rgba(16, 11, 55, 0.1), 0px 1px 2px rgba(16, 11, 55, 0.3)
      flex-shrink: 0
     
      @include d(sm)
       width: 100%

  .ceu-banner-2 
    // height: 2800px
    height: 100%
    pointer-events: none
    img
      height: 100%
      object-fit: cover
      width: 100%
  .to-stay
    position: absolute
    height: 100vh     
    top: 0
    z-index: 10
    pointer-events: none
    display: flex
    flex-direction: column
    justify-content: flex-end
    overflow: hidden

  .segundo-plano img
    height: 400px
    object-fit: cover
    object-position: left
    margin-bottom: -100px
  .pista img
    height: 236px
    object-fit: cover
    object-position: left
    
#sobre
  margin-top: 7.5rem
  margin-bottom:  7.5rem
  scroll-margin-top: 7.5rem

  
  .intro

    max-width: max-content
    transform: rotate(-10deg)
    margin-bottom: 3rem

    .title
      color: $blue-500
      
      font-size: 5.5rem
      font-weight: 900
      font-size: clamp(3rem, 4.4vw, 3rem)
     

      span
        background-color: $green-500
        padding: 10px 
      .to-break
        margin-left: -16px
      @include d(md)
       .to-break
        display: block
        margin-left: 0
        position: relative
        left: 50%
  
  .sobre-grid
    display: grid
    grid-template-columns: 1fr 1fr
    gap: 6.25rem 
    margin-bottom: 5rem

    @include d(md)
     grid-template-columns: 1fr
     gap: 3rem 

    .col-2
      .title
        font-weight: 700
        font-size: 2.25rem
        color: $blue-500
        margin-bottom: 8px
      .sobre-wrapper .sub-title
        color: #0F0F0F
        font-size: 1.125rem
        font-weight: 700
        margin-bottom: 1.5rem
      .sobre-wrapper p
        margin-bottom: 1rem 
        color: #0F0F0F
        font-size: 14px
        line-height: 1.4
      .sobre-cards
        margin-top: 1.5rem
        .card-item
          background-color: #fff
          border: 1px solid #00FF8C
          box-shadow: 0px 1px 4px rgba(16, 11, 55, 0.2), 0px 3px 8px rgba(16, 11, 55, 0.1)
          border-radius: 16px
          padding: 8px 24px 8px 40px
          position: relative
          max-width: 250px
          width: 100%
          margin-bottom: 1rem
          @include d(md)
            margin-left:  auto
            margin-right:  auto

          svg
            position: absolute
            top: 50%
            transform: translateY(-50%)
            left: -1.75rem
            width: 3.5rem
            height: 3.5rem
          p
            line-height: 1.4
            font-size: 1rem
          span
            font-weight: 700
        .card-item:nth-child(2)
          margin-left: auto
          margin-right: auto
        .card-item:nth-child(3)
          margin-left: auto
      .text-experimenta
        color: $blue-500
        font-size: 1.125rem
        line-height: 1.3
        font-weight: 700
        margin-top: 1.5rem
  


#faq
  background-color: $blue-500
  padding-top: 10rem
  padding-bottom: 10rem

  .intro

    max-width: max-content
    transform: rotate(-10deg)
    margin-bottom: 3rem
    margin-left: auto
    margin-right: auto
    position: relative
    right: 106px
    @include d(md)
      right: 0

    .title
      color: $blue-500
      
      font-size: 5.5rem
      font-weight: 900
      font-size: clamp(3rem, 4.4vw, 3rem)
      

      span
        background-color: $green-500
        padding: 10px
        display: block
      .to-break
        margin-left: 0
        margin-top: -1px
        display: block
        width: max-content
        margin-left: 0
        position: relative
        left: 70%
        @include d(md)
          left: 1rem

  .swiper-faq
    margin-bottom: 6rem
    @include d(md2)
      overflow: visible

    .swiper-slide
      height: auto
  .faq-cards
    .faq-card
      display: flex
      flex-direction: column

      height: 100%
      background-color: #fff
      box-shadow: 0px 1px 4px rgba(16, 11, 55, 0.2), 0px 3px 16px rgba(16, 11, 55, 0.1)
      border-radius: 8px
      padding: 24px
      border-bottom: 5px solid $green-500

      svg
        width: 3.5rem
        height: 3.5rem
        margin-bottom: 1rem
      .pergunta-title
        color: #0F0F0F
        font-size: 16px
        line-height: 1.3
        font-weight: 700
        margin-bottom: 1rem
      .resposta
        color: #0F0F0F
        font-size: 14px
        line-height: 1.4
        margin-bottom: 1.4rem
      .saber-mais
        margin-top: auto
  .cta-banner-wrapper
    max-width: 910px


#contato
  padding: 8rem 0
  .intro

    max-width: max-content
    transform: rotate(-10deg)
    margin-bottom: 3rem

    @include d(sm)
      margin-left: auto
      margin-right: auto

    .title
      color: $blue-500
      
      font-size: 5.5rem
      font-weight: 900
      font-size: clamp(3rem, 4.4vw, 3rem)

      .hide-mobile
        display: none
        @media (min-width: 768px)
          display: inline-block
          padding: 0
        
      .hide-desktop
        display: none
        @media (max-width: 768px)
          display: inline-block
          padding: 0
      span
        background-color: $green-500
        padding: 10px
        width: max-content
        display: block
        @media (max-width: 768px)
          margin: 0 auto
      .to-break
        margin-left: auto
        display: block
        position: relative
        left: 5rem
        @media (max-width: 768px)
          left: unset
          
      
      
      

  .grid-wrapper
    display: grid
    grid-template-columns: 1fr 1fr
    align-items: flex-end
    
    gap: 1rem
    @include d(md2)
      grid-template-columns: 1fr 

    .col-1
      // padding-bottom: 2rem 
      @include  d(md)
        // padding-top: 5rem
    .images-group
      transform: translateY(120px)
      @media (max-width: 1670px)
        transform: translateY(120px) scale(0.8)
      @media (max-width: 1024px)
        transform: translateY(0)
      // margin-top: 2rem
      @include d(md2)
        grid-row: 1
      .step
        position: relative
      .step:nth-child(2)
        transform: translateY(-44px)  
        @include d(md)
          transform: translateY(-30px)   
        img
          margin: 0 auto
          // padding-top: -44px       
          right: 10px
          filter: grayscale(1)
          
          @media (max-width: 1024px)
            filter: none
            // margin-top: -30px
      .step:nth-child(3)
        transform: translateY(-120px)
        transform: translateY(-80px)   
        @include d(md)
        img
          margin-left: auto
          // margin-top: -120px
          margin-right: 10px
          filter: grayscale(1)
          @media (max-width: 1024px)
            filter: none
          @include d(md)
            // margin-top: -80px


  .card-form
  
    background: $white
    box-shadow: 0px 1px 4px rgba(16, 11, 55, 0.2), 0px 3px 16px rgba(16, 11, 55, 0.1)
    border-radius: 1rem
    max-width: 587px  
    padding: 2.5rem 2rem
    display: flex
    flex-direction: column
    gap: 2.5rem
    transition: 0.2s ease
  
    @include d(sm)
      // width: 90%
      padding-inline: 1rem
    header
      display: flex
      flex-direction: column
      gap: 1rem

    h4
      font-size: 2.25rem
      color: $blue-500
      line-height: 2.75rem
      margin-block: 0

    span
      line-height: 1.5rem

  .input-validation-field
    display: flex
    flex-direction: column
    gap: .25rem
    position: relative

    p
      font-size: .75rem
      color: $invalid
      position: absolute
      top: 4.25rem

  .form-columns
    display: grid
    grid-template-columns: 1fr 1fr
    gap: 1.8vw
    @include d(sm)
      grid-template-columns: 1fr
      gap: 2rem

  form
    display: flex
    flex-direction: column
    gap: 2.5rem
    @include d(sm)
      gap: 2rem

  input
    border: 0
    width: 100%
    outline: 0
    color: $gray-900
    font-size: 1rem

    display: block
    padding-block: 0.375rem
    padding-inline: .625rem 0
    color: #212529
    background-clip: padding-box
    -webkit-appearance: none
    -moz-appearance: none
    appearance: none

  footer
    // margin-top: 16px
    display: flex
    align-items: center
    gap: .5rem

    button
      background: $blue-300
      border-radius: .75rem
      padding: .75rem
      color: $white
      font-size: 1rem
      font-weight: 700
      line-height: 1.5rem
      flex: 1
      transition: all 0.2s

      display: flex
      justify-content: center
      align-items: center
      gap: 10px

      svg
        color: $white

    button:hover
      background: $blue-400

  a.icon-question
    border: 1px solid $blue-500
    border-radius: .75rem
    padding: .75rem
    display: flex
    align-items: center
    width: fit-content
    transition: all 0.2s

    &:hover
      background: $blue-50

  svg
    width: 1.5rem
    height: 1.5rem
    color: $gray-500

  .input-field
    width: 100%
    display: flex
    align-items: center
    padding: 1rem 0 1rem 1rem
    border: 1px solid $blue-100
    border-radius: .75rem

    &.valido
      border: 1px solid $success !important
      padding-right: 1.125rem
      background-image: url("../assets/img/valid.svg")
      background-repeat: no-repeat
      background-position: right calc(.5625rem + .5625rem) center
      background-size: 1.5rem
      input
        width: calc(100% - 48px)

    &.invalido
      border: 2px solid $invalid !important
      padding-right: 1rem
      background-image: url("../assets/img/invalid.svg")
      background-repeat: no-repeat
      background-position: right 1.125rem center
      background-size: 1.5rem
      input
        width: calc(100% - 48px)

    &:focus-within
      border: 2px solid $active
      svg
        color: $gray-900


#blog
  background-color: $blue-500
  padding: 10rem 0

  .intro
    max-width: max-content
    transform: rotate(-10deg)
    margin-bottom: 3rem
    margin-left: auto
    margin-right: auto
    position: relative
    right: 0
    margin-bottom: 7rem
   
      

    .title
      color: $blue-500
      font-size: 5.5rem
      font-weight: 900
      font-size: clamp(3rem, 4.4vw, 3rem)
      span
        background-color: $green-500
        padding: 10px
        margin-left: -15px
        @include d(sm)
          display: block
          margin: 0 auto
          width: max-content
      .to-break
        @include d(sm)
         display: block
         margin-top: -2px
        

  .blog-wrapper 
    display: flex
    flex-direction: column
    gap: 2rem

  .main-card
    width: 100%
    display: grid
    grid-template-columns: 1fr 1fr
    align-items: center
    background: $white
    box-shadow: 0px 1px 4px rgba(16, 11, 55, 0.2), 0px 3px 16px rgba(16, 11, 55, 0.1)
    border-radius: 16px
    @include d(md)
      grid-template-columns: 1fr

    .main-card-img
      height: 100%

    img
      width: 100%
      height: 100%
      object-fit: cover
      object-position: bottom
      border-radius: 16px 0 0 16px
      @include d(md)
        border-radius: 16px 16px 0 0

    .main-card-info

      display: flex
      flex-direction: column
      gap: 1.5rem
      padding: 2rem
      @include d(md2)
        padding: 1.5rem 1rem 

    h5
      font-size: 1.5rem
      color: $blue-500
      line-height: 1.3
      margin-block: 0

    p
      line-height: 1.5rem
      color: $gray-900

    a
      color: $blue-500
      text-decoration: none
      margin-top: 2.5rem
      margin-left: auto

      display: flex
      align-items: center
      gap: .5938rem

      svg
        width: .9375rem
        height: .7813rem

  .swiper
    margin-inline: 0

    .swiper-slide
      box-shadow: 0px 1px 4px rgba(16, 11, 55, 0.2), 0px 3px 16px rgba(16, 11, 55, 0.1)
      border-radius: 8px

  .blog-card
    @include blog-card

    h6
      font-size: 1.5rem

  .menta-way
    width: 100%
    border-radius: 8px
    margin-block: 2.25rem
    padding: 4.5rem 1.5rem
    background: url('../assets/img/blog_img2.png') no-repeat
    background-size: cover
    color: $white
    text-align: center

    display: flex
    flex-direction: column
    justify-content: center
    align-items: center
    gap: .5rem

    button
      margin-top: 2rem
      background: $blue-500
      color: $white
      text-decoration: none
      font-weight: 700
      font-size: 1rem
      padding-block: .75rem
      cursor: pointer
      transition: all 0.2s

      display: flex
      justify-content: center
      max-width: 23.75rem
      width: 100%
      border-radius: 8px

    button:hover
      background: $blue-600

  h3
    font-size: 3rem
    font-weight: 900
    margin-block: 0
    line-height: 3.75rem

  .see-more
    display: flex
    justify-content: center

    a
      color: $blue-500
      text-decoration: none
      font-weight: 700
      border: 1px solid $blue-500
      margin-top: 32px
      max-width: 36.8125rem
      width: 100%
      padding: 1.125rem
      text-align: center
      border-radius: 12px
      transition: all 0.2s
    a:hover
      background: $blue-500
      color: $white


#trabalhe-conosco
  padding: 10rem 0
  padding-bottom: 5rem
  .intro
    max-width: max-content
    transform: rotate(-10deg)
    margin-bottom: 7rem
    margin-left: auto
    margin-right: auto
    position: relative
    right:0

    @include d(md2)
     max-width: 320px
     padding: 0 1.5rem
     margin-bottom: 3rem


    .title
      color: $blue-500
      font-size: 5.5rem
      font-weight: 900
      font-size: clamp(3rem, 4.4vw, 3rem)
      line-height: 1
      span
        background-color: $green-500
        padding: 10px
        margin-left: -15px
        @include d(md2)
          text-transform: capitalize
       
      .to-break
        // margin-left: 0
        // margin-top: -1px
        // display: block
        // width: max-content
        // margin-left: 0
        // position: relative
        // left: 70%
        @include d(md2)
          display: block
          width: max-content
          // left: 1rem
      .to-break:nth-child(2)
        margin: 0 auto
      .to-break:nth-child(3)
        margin-left: auto
  

  .swiper-trabalhe
    @media (max-width: 1024px)
      overflow: visible
  .card-trabalho
    background-color: #fff
    border-radius: 16px
    overflow: hidden
    border: 1px solid #fff

    .img-wrapper
      img 
        width: 100%
    
    svg
      width: 18px
      height: 18px
    .content
      padding: 16px 16px 19px
      .title
        color: $gray-900
        font-size: 14px 
        font-weight: 700
        line-height: 1.3
        margin-bottom: 8px
      .info-vaga
        color: $gray-500
        font-size: 14px
        margin-bottom: 18px
    .ver-mais
      color: $blue-500 
      font-weight: 700
      display: flex 
      align-items: center
      justify-content: center
      letter-spacing: 0.0015em
      text-decoration:  none

      svg
        margin-left: 8px

  .wrapper-info
    max-width: 586px
    margin: 0 auto
    text-align: center
    margin-top:  2rem
    .ver-mais
      color: $blue-500 
      font-weight: 700
      display: flex 
      align-items: center
      justify-content: center
      letter-spacing: 0.0015em
      text-decoration:  none
      margin-top: 1.5rem

      svg
        margin-left: 8px
        width: 18px
        height: 18px



