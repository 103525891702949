
@import "./variaveis"
@import './components/body'
@import "./utils/reset"
@import "./utils/responsivo"
@import "./mixins"

@import "./components/menu"
@import "./components/footer"
@import "./components/cta-base"
@import "./components/modal"

body
  opacity: 0

main
  overflow: hidden

.PageFaq
  @import './pages/page_faq'

.PageEmBreve
  @import './pages/page_em_breve'

.PagePost
  @import './pages/page_post'

.PageBlog
  @import './pages/page_blog'

.PageHome
  @import './pages/page_home'

.PageTrabalhe
  @import './pages/page_trabalhe'
.SingleTrabalhe 
  @import './pages/single_trabalhe'

.PageContato
  @import './pages/page_contato'
