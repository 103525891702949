&
  background: $background
.section-header
  @include section-header()
  .section-content
    top: 0
    padding-top: 2.5rem
    padding-bottom: 2.5rem
  .faq-img
    @include d(md)
      position: relative
      left: 1.5rem
    img
      margin-left: auto

#contato
  padding-top: 6.25rem
  margin-bottom: 4.5rem
  .grid-wrapper
    display: grid
    align-items: flex-start
    grid-template-columns: 0.7fr 0.3fr
    gap: 2rem
  
    @include d(md2)
      grid-template-columns: 1fr
   

    .col-1,.side-bar
      background-color: #fff
      box-shadow: 0px 1px 4px rgba(16, 11, 55, 0.2), 0px 3px 16px rgba(16, 11, 55, 0.1)
      border-radius: 8px

    .col-1
      padding-bottom: 1.5rem
      h2
        padding: 1.5rem
        border-bottom: 1px solid #A3A3A3
      p
        font-weight: 400 
        color: $gray-900
        line-height: 1.3
      .descricao
        margin-bottom: 1.5rem

      .bloco-wrapper
        padding: 1.5rem
        padding-bottom: 0
        .blog-group
          border-bottom: 1px solid #A3A3A3
        .bloco
          margin-bottom: 1.5rem

          ul:not(.no-disc)
            list-style: disc
            li
              list-style: disc
              margin-left: 1.5rem
          p
            margin-bottom: 8px
          a
            font-weight: 700
            display: block
            text-decoration: none
            margin-bottom: 8px
            color: $gray-900
          .chat-link
            color: $blue-500
            text-decoration: underline
          
    .col-2
      @include d(md2)
        grid-row: 1
      .side-bar
        margin-bottom: 1.5rem     
        padding: 1.5rem
        .icon-wrapper
          display: flex
          align-items: center
          margin-bottom: 1rem
          svg
            width: 2.5rem
            height: 2.5rem
            color: $blue-800
            margin-right: 1rem
          p
            color: $blue-800
            font-size: 1.25rem
            font-weight: 700
        .side-info

          p
            color: $blue-800
            margin-bottom: 5px
          a
            color: $blue-800
            font-weight: 700
