&
  font-family: "vinila"
  overflow-x: hidden
  background: $background

.section-header
  @include section-header
 

  .title-bg
    @include d(sm)
      padding-inline: .45rem
  .faq-img
    @include d(md)
      position: relative
      left: 1.5rem
      img
        margin-left: auto

.faq-cards
  
  display: flex
  justify-content: center
  align-items: center
  flex-direction: column
  padding-block: 6.25rem
  gap: 1.5rem
  padding-inline: 1.5rem
  @include d(sm)
    padding-inline: 0.5rem
    padding-block: 3.5rem 6.25rem

.faq-card
  @include faq-card
