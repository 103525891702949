footer.faq-footer
  background: $blue-800
  color: $white

  .footer-wrapper
    // max-width: 1075px
    // width: 100%
    display: flex
    justify-content: space-between
    align-items: center
    padding: 2rem 0 
    text-align: center
    // margin-inline: auto
    

    @include d(sm)
      flex-direction: column
      
      align-items: flex-start
      text-align: left
      gap: 1.5rem
      padding: 1.5rem 1rem

.about-menta
  display: flex
  max-width: 25rem
  width: 100%
  justify-content: space-between
  align-items: center
  @include d(md)
    max-width: 18.75rem
  @include d(sm)
    align-items: flex-start
    margin-top: .5rem
    flex-direction: column
    justify-content: center
    gap: 1.5rem

  a
    color: $white
    text-decoration: underline
    line-height: 1.25rem
    display: block
    font-weight: 300
    transition: 0.2s ease
    &:hover
      color: $green-500

  .about-col
    display: flex
    flex-direction: column
    gap: 0.5rem
    text-align: left

.menta-online
  display: flex
  flex-direction: column
  align-items: flex-start
  gap: 0.5rem

  span
    font-weight: 700
    font-size: 16px
    margin-bottom: .625rem

  .social-media
    display: flex
    align-items: center
    gap: 2rem
    a 
      display: block
      height: 24px
      transition: 0.2s ease
      &:hover
        transform: scale(1.1)    
    svg
      color: $white
      width: 24px
      height: 24px

footer.faq-footer
  .all-rights-reserved
    padding-block: 1.6rem
    width: 100%
    border-top: 1px solid $white

    display: flex
    justify-content: center
    align-items: center
    text-align: center
    @include d(sm)
      flex-direction: column
      span, p
        font-size: .875rem
        line-height: 1.25rem
    p::before
      content: '•'
      padding-inline: 8px
      @include d(sm)
      content: ''
