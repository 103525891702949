.cta-banner-wrapper
  background: #fff
  padding: 1.5rem 2rem
  box-shadow: 0px 1px 4px rgba(16, 11, 55, 0.2), 0px 3px 16px rgba(16, 11, 55, 0.1)
  border: 1px solid #A3A3A3
  border-radius: 32px

  display: flex
  justify-content: space-between
  align-items: center
  gap: 1rem
  
  max-width: 992px
  width: 100%
  margin: 0 auto
  
  @include d(md2)
    flex-direction: column
  @include d(md2)
    padding: 1.5rem 1rem
  p
    color: #0F0F0F
    font-size: 1.5rem
    font-weight: 700
    line-height: 1.3

    @include d(md)
    font-size: 1.3rem
    text-align: center
    

  .btn-primary
    @include Botao()
    box-shadow: 0px 1px 4px rgba(16, 11, 55, 0.1), 0px 1px 2px rgba(16, 11, 55, 0.3)
    flex-shrink: 0
    border: 1px solid #5037FF
    color: #5037FF
    background-color: #fff
    svg
      width: 24px
      height: 24px 
    &:hover
      color: #fff
        
    @include d(sm)
      width: 100%
