.modal
  opacity: 0

  pointer-events: none
  background: rgba(0, 0, 0, 0.35)
  position: fixed
  z-index: 100
  top: 0
  left: 0
  width: 100vw
  height: 100vh
  transition: 0.2s ease

  &.modal-active
    opacity: 1
   

    .card-form
      transform: translate(-50%, -50%) scale(1)
      pointer-events: all

 

  .card-form svg.close-modal
    position: absolute
    top: 1.9rem
    right: 2rem
    color: #0F0F0F
    cursor: pointer
    width: 16px
    height: 16px
    &:hover
      color: #C82400

   
  header
    display: flex
    flex-direction: column
    gap: 1rem

  h4
    font-size: 2.25rem
    color: $blue-500
    line-height: 2.75rem
    margin-block: 0

  span
    line-height: 1.5rem

  .card-form
    position: fixed
    left: 50%
    top: 50%
    transform: translate(-50%, -50%) scale(0)
    background: $white
    box-shadow: 0px 1px 4px rgba(16, 11, 55, 0.2), 0px 3px 16px rgba(16, 11, 55, 0.1)
    border-radius: 1rem
    max-width: 587px
    width: 96%
    // padding-block: 2rem
    // padding-inline: 3vw
    padding: 2.5rem 2rem

    display: flex
    flex-direction: column
    gap: 2.5rem
    transition: 0.2s ease
    
    @include d(sm)
      width: 90%
      padding-inline: 1rem

    .input-validation-field
      display: flex
      flex-direction: column
      gap: .25rem
      position: relative

      p
        font-size: .75rem
        color: $invalid
        position: absolute
        top: 4.25rem

    .form-columns
      display: grid
      grid-template-columns: 1fr 1fr
      gap: 1.8vw
      @include d(sm)
        grid-template-columns: 1fr
        gap: 2rem

    form
      display: flex
      flex-direction: column
      gap: 2.5rem
      @include d(sm)
        gap: 2rem

    input
      border: 0
      width: 100%
      outline: 0
      color: $gray-900
      font-size: 1rem

      display: block
      padding-block: 0.375rem
      padding-inline: .625rem 0
      color: #212529
      background-clip: padding-box
      -webkit-appearance: none
      -moz-appearance: none
      appearance: none

    footer
      // margin-top: 16px
      display: flex
      align-items: center
      gap: .5rem

      button
        background: $blue-300
        border-radius: .75rem
        padding: .75rem
        color: $white
        font-size: 1rem
        font-weight: 700
        line-height: 1.5rem
        flex: 1
        transition: all 0.2s

        display: flex
        justify-content: center
        align-items: center
        gap: 10px

        svg
          color: $white

      button:hover
        background: $blue-400

    a.icon-question
      border: 1px solid $blue-500
      border-radius: .75rem
      padding: .75rem
      display: flex
      align-items: center
      width: fit-content
      transition: all 0.2s

      &:hover
        background: $blue-50

    svg
      width: 1.5rem
      height: 1.5rem
      color: $gray-500

  .input-field
    width: 100%
    display: flex
    align-items: center
    padding: 1rem 0 1rem 1rem
    border: 1px solid $blue-100
    border-radius: .75rem

    &.valido
      border: 1px solid $success !important
      padding-right: 1.125rem
      background-image: url("../assets/img/valid.svg")
      background-repeat: no-repeat
      background-position: right calc(.5625rem + .5625rem) center
      background-size: 1.5rem
      input
        width: calc(100% - 48px)

    &.invalido
      border: 2px solid $invalid !important
      padding-right: 1rem
      background-image: url("../assets/img/invalid.svg")
      background-repeat: no-repeat
      background-position: right 1.125rem center
      background-size: 1.5rem
      input
        width: calc(100% - 48px)

    &:focus-within
      border: 2px solid $active
      svg
        color: $gray-900
