&
  overflow: hidden

.intro
  background: $green-500
  transform: rotate(-10deg)
  max-width: fit-content
  padding-inline: 1.5rem
  margin-inline: auto
  // transition: clip-path .3s ease

h1
  color: $blue-500
  font-weight: 900
  font-size: 5.5rem
  line-height: 7rem
  margin-block: 6rem

  .conteudo-intermediario
    display: flex
    gap: 4rem

svg
  width: 1.5rem
  height: 1.5rem

.quebra
  display: none

.cloud-1
  position: fixed
  width: calc(100vw + 400px)
  height: auto
  z-index: -2
  top: 200px
  left: -200px
  right: -200px
  margin: 0 auto

.cloud-2
  position: fixed
  width: calc(100vw + 400px)
  height: auto
  z-index: -1
  top: 250px
  left: -200px
  right: -200px
  margin: 0 auto
