.conteudo
  .img-clouds
    img
      width: 100%
      height: 100%
      position: absolute
      top: 7.8125rem
      bottom: 0
      left: 0
      z-index: -1
      // @include d(md2)
      //   top: 5.9375rem
      // @include d(sm)
      //   top: -10vh
  .img-circle
    img
      width: 100%
      height: 100%
      position: absolute
      top: 16.6875rem
      bottom: 0
      left: 0
      z-index: -1
    // @include d(max2xl)
    //   img
    //     top: 16.5625rem
    // @include d(lg)
    //   img
    //     top: 16.25rem
    // @include d(md2)
    //   img
    //     top: 15.3125rem
    // @include d(sm)
    //   img
    //     object-fit: contain
  .conteudo-intermediario
    .img-airplane
      img
        max-width: 40.4375rem
        width: 100%
        // position: absolute
        // bottom: 15vh
        // right: 30vh
        object-fit: contain
        // @include d(max2xl)
        //   bottom: 10vh
        // @include d(xl)
        //   right: 25vh
        // @include d(lg)
        //   right: 20vh
        //   bottom: 8vh
        // @include d(sm)
        //   width: 25rem
        //   top: 25vh
        //   bottom: 0
        //   right: 1vh
