//======== CORES =============

// NEUTRAL
$white: #FFFFFF,
$black: #000000,

$gray-50: #F6F6F6,
$gray-100: #E0E0E0,
$gray-200: #C2C2C2,
$gray-300: #A3A3A3,
$gray-400: #858585,
$gray-500: #666666,
$gray-600: #474747,
$gray-700: #333333,
$gray-800: #1F1F1F,
$gray-900: #0F0F0F,

// BRAND
$green-50: #EBFFF6,
$green-100: #D6FFED,
$green-200: #ADFFDA,
$green-300: #85FFC8,
$green-400: #47FFAC,
$green-500: #00FF8C,
$green-600: #00E07B,
$green-700: #00B865,
$green-800: #008F4F,
$green-900: #00522D,

$blue-50: #EDEBFF,
$blue-100: #C8C2FF,
$blue-200: #A399FF,
$blue-300: #857AEA,
$blue-400: #6F5CFF,
$blue-500: #5037FF,
$blue-600: #1D00F5,
$blue-700: #1800CC,
$blue-800: #1202A4,
$blue-900: #0C0066,

// CONTEXT
$background: #EBEAF4,
$success: #1CC800,
$active: #2648BE,
$invalid: #C82400,

// FLOWKIT
$purple: #7B61FF,
$charcoal: #222222,
$red: #FC5555,
$green: #29CC6A,
$blue: #0099FF
