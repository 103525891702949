&
  background: $background
.section-header
  @include section-header()

#vagas
  padding-top: 8rem
  margin-bottom: 3.375rem
  position: relative
  z-index: 2
  @include  d(md)
    padding-top: 5rem
  .grid-wrapper
    display: grid
    grid-template-columns: repeat(3, 1fr)
    gap: 2rem
    margin-bottom: 2rem
    @include d(md)
     grid-template-columns: repeat(1, 1fr)
     gap: 1rem

    .group
     
      select
        background-color: #fff
        width: 100%
        border: 1px solid #C8C2FF
        border-radius: 12px
        padding: 1rem
        color:#666666
        appearance: none
        height: 3.5rem

        background-image: url('../assets/img/chevron-down.svg')
        background-repeat: no-repeat
        background-position: 98%
        background-size: 24px
        font-family: 'Vinila'
        cursor: pointer
  .vagas-wrapper
    .vaga
      text-decoration: none
      background-color: #fff
      border-radius: 16px
      box-shadow: 0px 1px 4px rgba(16, 11, 55, 0.2), 0px 3px 16px rgba(16, 11, 55, 0.1)
      padding: 1.875rem 1.5rem  
      display: flex
      align-items: center
      justify-content: space-between
      margin-bottom: 1rem 
      @include d(md)
       padding: 1rem
      .infos
        display: flex
        align-items: center
        align-items: center
        gap: 10px
        display: flex
        justify-content: space-between
        display: grid
        grid-template-columns: 1fr 1fr 1fr
        flex: 1
        @include d(md)
         display: block
        .title
          color: $gray-900
      
        p
         color: $gray-500
         line-height: 1.8
         @include d(md)
         line-height: 1.3
      .ver-link
        .link
          display: flex
          align-items: center
          color: $blue-500
          text-decoration: none
        svg
          width: 18px
          height: 18px
          margin-left: 8px
  
        
         
