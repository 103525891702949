$xl: 1920px
$lg: 1440px
$md2: 1024px
$md: 800px
$sm: 600px

// Mixin media queries

@mixin d($size)
  @if $size== xl
    @media screen and (max-width: $xl)
      @content

  @else if $size== lg
    @media screen and (max-width: $lg)
      @content

  @else if $size== md2
    @media screen and (max-width: $md2)
      @content

  @else if $size== md
    @media screen and (max-width: $md)
      @content

  @else if $size== sm
    @media screen and (max-width: $sm)
      @content
