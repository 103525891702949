@mixin section-header()
  min-height: 164px
  margin-top: 69px

  background: $green-500
  display: flex
  justify-content: space-between
  gap: 4rem
  // padding-left: 5vw
  padding-top: 2.5rem
  position: relative
  @include d(md2)
    // padding-left: 2vw
  @include d(md)
    flex-direction: column
    // padding-left: 1vw
    gap: .5rem
  @include d(md)
    padding: 3rem 0

  .section-content
    position: relative
    top:28px
    width: max-content
    @include d(sm)
     top: unset
  h2
    font-size: 3.375rem //54px
    color: $blue-500
    text-transform: uppercase
    line-height: 1
    font-weight: 900
    @include d(sm)
     text-align: center

  .title-bg
    background: $blue-500
    width: max-content
    // color: $green-500
    // clip-path: polygon(0 20%, 99% 0%, 100% 80%, 1% 100%)
    transform: rotate(-4deg)
    padding: 0.5rem 2.8rem 0.1rem
    text-align: center
    margin-bottom: 16px
    @include d(md)
      // width: fit-content
      // margin-inline: auto
    h2
      color: #FFFFFF
      line-height: 1
      
    @include d(sm)
      // width: 95%
      // padding-inline: 1.5rem

  h2.relative
    position: relative
    margin-left: auto
    width: max-content
    left: 3rem
    @include d(sm)
      left: unset
      text-align: center
      margin-right: auto
    // left: 26%
    // width: fit-content

    @include d(sm)
      // position: static
      // margin-inline: auto

  .faq-img
    max-width: 48vw
    position: absolute
    bottom: -20px
    right: 0
    @include d(md2)
      max-width: 42vw
    @include d(md)
      position: static
      max-width: 100vw
      margin-left: auto

    

@mixin title-bg()
  background: $blue-500
  color: $green-500
  clip-path: polygon(0 20%, 99% 0%, 100% 80%, 1% 100%)
  padding: 0.4rem 2.8rem

@mixin faq-card()
  max-width: 1000px
  width: 100%
  padding: 1.5rem
  background: $white
  box-shadow: 0px 1px 4px rgba(16, 11, 55, 0.2), 0px 3px 16px rgba(16, 11, 55, 0.1)
  border-radius: 8px
  display: flex
  flex-direction: column
  gap: 1rem

  .faq-card-title
    display: flex
    gap: 1rem
    height: fit-content

    svg
      width: 2rem
      height: 2rem

    h5
      font-size: 1.5rem
      font-weight: bold
      color: $blue-800
      line-height: 2rem
      margin-block: 0
      padding-block: 0

  span
    font-size: 1rem
    color: $gray-900
    line-height: 1.5rem

@mixin blog-card()
  display: flex
  flex-direction: column
  background: $white
  min-height: 23rem
  border-radius: 8px

  img
    width: 100%
    height: 100%
    border-radius: 8px 8px 0 0

  h6
    color: $blue-500
    font-size: 24px
    margin-block: 0
    line-height: 2rem
    padding: 1.5rem 1.5rem 0

  a
    color: $blue-500
    font-weight: 700
    text-decoration: none
    margin: auto 1.5rem 1.5rem auto
    width: fit-content

    display: flex
    align-items: center
    gap: .5938rem

    svg
      width: .9375rem
      height: .7813rem


@mixin Botao()
  background: $blue-500
  color: $white
  text-decoration: none
  font-weight: 700
  font-size: 16px
  padding-block: .75rem
  cursor: pointer
  transition: all 0.2s

  display: flex
  justify-content: center
  align-items: center
  padding: 12px 2rem
  border-radius: 12px

  svg
    width: 24px
    height: 24px
    margin-left: 8px
  &:hover
    background: $blue-600
