html
  --font-base: 16px
  font-size: var(--font-base)



html:not(.is-ios)
  // scroll-behavior:  smooth
.container
  max-width: calc( 1206px + 3rem  )
  margin: 0 auto
  width: 100%
  padding: 0 1.5rem

@media (max-width: 1446px)
  html
    font-size: calc(var(--font-base) - 10.25%) 

@media (max-width: 980px)
  html
    font-size: calc(var(--font-base) - 12.5%)

@media (max-width: 340px)
  html
    font-size: calc(var(--font-base) - 30%)
