.conteudo-intermediario
  display: flex
  justify-content: center

  .card-form
    width: 30.5rem
    background: white
    padding: 2rem 1.5rem
    border-radius: 1rem
    box-shadow: 0rem .0625rem .25rem rgba(16, 11, 55, 0.2), 0rem .1875rem 1rem rgba(16, 11, 55, 0.1)
    // position: absolute
    // bottom: 17.5vh
    // left: 35vh
    // @include d(max2xl)
    //   bottom: 9.5vh
    // @include d(xl)
    //   width: 26.25rem
    //   padding: 1.5rem 1.5rem
    //   bottom: 6.5vh
    // @include d(lg)
    //   bottom: 9vh
    //   left: 25vh
    // @include d(md2)
    //   bottom: 8.5vh
    //   left: 25vh
    // @include d(sm)
    //   left: 2.5vh
    //   width: 23.125rem
    //   bottom: 10vh
    h2
      font-size: 1.5rem
      font-weight: 700
      color: #4F37FF
      line-height: 2rem
      // @include d(sm)
      //   font-size: 1.25rem
      //   line-height: 1.5rem
    p
      width: 22rem
      font-weight: 400
      font-size: .875rem
      line-height: 1.375rem
      color: #0F0F0F
      margin-top: 1rem
      // @include d(sm)
      //   font-size: .75rem
      //   line-height: 1.25rem
      //   width: 17.5rem
    .formulario
      .input-field
        position: relative
        margin-top: 2rem
        img
          position: absolute
          left: .75rem
          right: initial
          top: 50%
          transform: translateY(-50%)
          margin: 0 auto
          width: 1.5rem
          height: 1.5rem
        input
          background: white
          border: .0625rem solid #C8C2FF
          border-radius: .75rem
          padding: .75rem 1rem
          width: 100%
          padding-left: 2.5rem
          font-weight: 400
          font-size: 1rem
          line-height: 1.5rem
          color: #666666

      .input-icon-right
        position: relative
        width: 100%
        img
          position: absolute
          right: 30%
          left: initial
          top: 50%
          transform: translateY(-50%)
          margin: 0 auto
          width: 1.5rem
          height: 1.5rem
          // @include d(sm)
          //   right: 15%
          // @include d(xl)
          //   right: 25%
      .conteudo-final
        display: flex
        align-items: center
        margin-top: 1.5rem
        gap: .5rem

        .icon-question
          border: .0625rem solid #4F37FF
          border-radius: .75rem
          padding: 0
          min-width: 3rem
          height: 3rem
          width: 3rem
          display: grid
          place-items: center
          transition: .3s ease
          position: relative
          cursor: pointer
          .tooltip
            border: .0625rem solid $blue-500
            position: absolute
            bottom: calc(100% + .3125rem)
            right: 0
            left: 0
            margin: 0 auto
            width: 25rem
            height: 8.125rem
            background: white
            color: $blue-400
            z-index: 100
            padding: .5rem
            border-radius: .375rem
            transition: .3s ease
            opacity: 0
            pointer-events: none
            display: grid
            place-items: center
            font-size: 1rem
            line-height: 150%
            box-shadow: rgba(99, 99, 99, 0.2) 0rem .125rem .5rem 0rem

          &:hover
            background-color: $blue-50
            .tooltip
              opacity: 1

          img
            width: 1.5rem
            height: 1.5rem
            position: relative
            z-index: 2
        button
          background: #857AEA
          padding: .75rem 1.5rem
          width: 100%
          border-radius: .75rem
          font-weight: 700
          font-size: 1rem
          // line-height: 1.5rem
          color: white
          cursor: pointer
          transition: background .3s ease
          svg
            transition: transform .3s ease
          &:hover
            background: $blue-400
            svg
              transform: translateX(6.875rem)
              @include d(md2)
                transform: translateX(50px)

